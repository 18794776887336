import { useState } from "react";
import { styled } from "src/style/theme";
import { Button } from "./button";

const Table = styled("div", {
  borderRadius: 16,
  padding: 24,

  variants: {
    covered: {
      true: {
        border: "3px solid #B8C3FB",
        boxShadow:
          "0px 0px 2px rgba(20, 66, 131, 0.1), 0px 2px 10px rgba(20, 66, 131, 0.1)",
      },
      false: {
        border: "1px solid #DEDFE6",
      },
    },
  },
});

const TableContent = styled("div", {
  display: "block",

  "> div": {
    textAlign: "center",
    width: "100%",
  },

  "> div > div": {
    justifyContent: "center",
  },

  "@bp1": {
    // display: "flex",

    "> div": {
      textAlign: "left",
    },

    "> div > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "unset",
    },
  },
});

const TableList = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: 20,
  flex: 1,

  "@bp1": {
    width: "100%",
    marginTop: 0,
  },
});

const Title = styled("h1", {
  margin: 0,
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: 700,
  marginLeft: 10,
  color: "#101531",
});

const SubTitle = styled("p", {
  margin: "0px 5px 0px 0px",
  fontSize: 14,
  lineHeight: "16px",
  color: "#757575",
  marginTop: 22,

  "@bp1": {
    margin: "10px 0 ",
  },
});

const Text = styled("p", {
  margin: 0,
  fontSize: 12,
  lineHeight: "16px",
  color: "#101531",
});

export const CoverTable = ({
  covered = false,
  items,
  defaultNumberOfItems = 1,
  title,
  subTitle,
  moreItemLabel = "Voir plus",
}: {
  covered: boolean;
  items: String[];
  defaultNumberOfItems?: number;
  title: string;
  subTitle: string;
  moreItemLabel?: string;
}) => {
  const [displayAll, setDisplayAll] = useState(false);

  const list = displayAll ? items : items.slice(0, defaultNumberOfItems);

  return (
    <div>
      <Table covered={covered}>
        <TableContent>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                {covered ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.72 6.79L8.43001 11.09L6.78 9.44C6.69036 9.33532 6.58004 9.2503 6.45597 9.19027C6.33191 9.13025 6.19678 9.09652 6.05906 9.0912C5.92134 9.08588 5.78401 9.10909 5.65568 9.15936C5.52736 9.20964 5.41081 9.28589 5.31335 9.38335C5.2159 9.4808 5.13964 9.59735 5.08937 9.72568C5.03909 9.854 5.01589 9.99133 5.02121 10.1291C5.02653 10.2668 5.06026 10.4019 5.12028 10.526C5.1803 10.65 5.26532 10.7604 5.37 10.85L7.72 13.21C7.81344 13.3027 7.92426 13.376 8.0461 13.4258C8.16794 13.4755 8.2984 13.5008 8.43001 13.5C8.69234 13.4989 8.94374 13.3947 9.13 13.21L14.13 8.21C14.2237 8.11704 14.2981 8.00644 14.3489 7.88458C14.3997 7.76272 14.4258 7.63201 14.4258 7.5C14.4258 7.36799 14.3997 7.23728 14.3489 7.11542C14.2981 6.99356 14.2237 6.88296 14.13 6.79C13.9426 6.60375 13.6892 6.49921 13.425 6.49921C13.1608 6.49921 12.9074 6.60375 12.72 6.79ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
                      fill="#4E68F6"
                    />
                  </svg>
                ) : (
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.90994 8.99994L16.2099 2.70994C16.3982 2.52164 16.504 2.26624 16.504 1.99994C16.504 1.73364 16.3982 1.47825 16.2099 1.28994C16.0216 1.10164 15.7662 0.99585 15.4999 0.99585C15.2336 0.99585 14.9782 1.10164 14.7899 1.28994L8.49994 7.58994L2.20994 1.28994C2.02164 1.10164 1.76624 0.99585 1.49994 0.99585C1.23364 0.99585 0.978243 1.10164 0.789939 1.28994C0.601635 1.47825 0.495847 1.73364 0.495847 1.99994C0.495847 2.26624 0.601635 2.52164 0.789939 2.70994L7.08994 8.99994L0.789939 15.2899C0.696211 15.3829 0.621816 15.4935 0.571048 15.6154C0.520279 15.7372 0.494141 15.8679 0.494141 15.9999C0.494141 16.132 0.520279 16.2627 0.571048 16.3845C0.621816 16.5064 0.696211 16.617 0.789939 16.7099C0.882902 16.8037 0.993503 16.8781 1.11536 16.9288C1.23722 16.9796 1.36793 17.0057 1.49994 17.0057C1.63195 17.0057 1.76266 16.9796 1.88452 16.9288C2.00638 16.8781 2.11698 16.8037 2.20994 16.7099L8.49994 10.4099L14.7899 16.7099C14.8829 16.8037 14.9935 16.8781 15.1154 16.9288C15.2372 16.9796 15.3679 17.0057 15.4999 17.0057C15.632 17.0057 15.7627 16.9796 15.8845 16.9288C16.0064 16.8781 16.117 16.8037 16.2099 16.7099C16.3037 16.617 16.3781 16.5064 16.4288 16.3845C16.4796 16.2627 16.5057 16.132 16.5057 15.9999C16.5057 15.8679 16.4796 15.7372 16.4288 15.6154C16.3781 15.4935 16.3037 15.3829 16.2099 15.2899L9.90994 8.99994Z"
                      fill="#FF7777"
                    />
                  </svg>
                )}
              </div>
              <Title>{title}</Title>
            </div>
            <SubTitle>{subTitle}</SubTitle>
          </div>
          <TableList>
            {list.map((listItem, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width:
                      list.length % 2 !== 0 && list.length - 1 === index
                        ? "100%"
                        : "47.5%",
                    marginBottom: 8,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: covered
                        ? "#C0D8F9"
                        : "rgba(255, 119, 119, 0.2)",
                      padding: 8,
                      borderRadius: 12,
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Text>{listItem}</Text>
                  </div>
                </div>
              );
            })}
            {list.length < items.length && (
              <div
                style={{
                  width: "100%",
                  marginBottom: 8,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: covered
                      ? "#C0D8F9"
                      : "rgba(255, 119, 119, 0.2)",
                    padding: 8,
                    borderRadius: 12,
                    display: "flex",
                    justifyContent: "center",
                    width: "47.5%",
                  }}
                >
                  <Text>...</Text>
                </div>
              </div>
            )}
          </TableList>
        </TableContent>
        {list.length < items.length && (
          <div style={{ marginTop: 24 }}>
            <Button
              variant={covered ? "primary" : "outlined"}
              fullWidth
              onClick={() => setDisplayAll(true)}
              style={{ fontWeight: covered ? 700 : 400 }}
            >
              {moreItemLabel}
            </Button>
          </div>
        )}
      </Table>
    </div>
  );
};
