import { css, keyframes } from "src/style/theme";

type SpinnerProps = {
  size?: number;
  strokeWidth?: number;
  strokeColor?: string;
  strokeActiveColor?: string;
  speed?: number;
}

const rotate = keyframes({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
});

const loader = (size: number, strokeWidth: number, strokeColor: string, strokeActiveColor: string, speed: number) => css({
  border: `${strokeWidth}px solid ${strokeColor}`,
  borderTop: `${strokeWidth}px solid ${strokeActiveColor}`,
  borderRadius: "50%",
  width: size,
  height: size,
  animation: `${rotate} ${speed}s linear infinite`
});

export const Spinner = ({
  size = 25,
  strokeWidth = 2.5,
  strokeColor = "#f3f3f3",
  strokeActiveColor = "#3498db",
  speed = 1
}: SpinnerProps) => {
  return (
    <div className={loader(size, strokeWidth, strokeColor, strokeActiveColor, speed)()} />
  );
}
