import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

import { theme, styled } from "src/style/theme";
import { ExternalLinkIcon } from "./icons";

type Props = {
  icon?: React.ReactNode;
  label: string;
  link: string;
};

const ButtonClass = styled("button", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  backgroundColor: "transparent",
  borderWidth: 0,
  padding: "12px 16px",
  borderRadius: 8,
  cursor: "pointer",

  variants: {
    isActive: {
      true: {
        backgroundColor: theme.colors.blue200,
      },
    },
  },
});

function LinkWrapper({
  to,
  children,
  ...props
}: {
  to: Props["link"];
  style: Object;
  children: ReactNode;
}) {
  if (to.match(/^https?:\/\//)) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
}

const NavLinkButton = ({ icon, label, link }: Props) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <LinkWrapper
      style={{
        textDecoration: "none",
        WebkitTapHighlightColor: "transparent",
        borderBottom: 0,
      }}
      to={link}
    >
      <ButtonClass isActive={isActive}>
        {icon && (
          <div
            style={{ marginRight: 14, display: "flex", alignItems: "center" }}
          >
            {icon}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <p
            style={{
              margin: 0,
              color: isActive ? "#4E68F6" : "#616161",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {label}
          </p>
          {link.match(/^https?:\/\//) ? (
            <div>
              <ExternalLinkIcon size={20} />
            </div>
          ) : null}
        </div>
      </ButtonClass>
    </LinkWrapper>
  );
};

export default NavLinkButton;
