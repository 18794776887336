export const SANDBOX = process.env.REACT_APP_PRODUCTION !== "true";

export const legal = {
  v1: {
    cgu: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+Conditions+Generales.pdf",
    ipid: "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+IPID.pdf",
    feuille_de_soins:
      "https://editique.s3.eu-west-3.amazonaws.com/public/formule_dalma_blue/v1/dalma.blue+-+Feuille+de+soin.pdf",
    legal_notice:
      "https://editique.dalma.co/formule_dalma_blue/v2/dalma.blue-Mentions-legales.pdf",
    privacy_policy:
      "https://editique.dalma.co/formule_dalma_blue/v2/dalma.blue-Politique-de-confidentialite.pdf",
  },
};

export const MARKETING = SANDBOX
  ? "https://www.dev.dalma.blue"
  : "https://www.dalma.blue";

export const INTERCOM_APP_ID = "zkur88l2";

export const INVOICE_DOMAIN = "https://pdfkit.dalma.tech/invoices";
