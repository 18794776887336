import { css, theme } from "src/style/theme";

const sandbox = css({
  position: "fixed",
  background: theme.colors.fire,
  color: theme.colors.white,
  padding: "8px 40px",
  transform: "translate(-47px, 15px) rotate(-45deg)",
  textTransform: "uppercase",
  zIndex: 10,
  opacity: 0.8,
});

export function Sandbox() {
  return <div className={sandbox()}>Sandbox</div>;
}
