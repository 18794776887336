import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SANDBOX } from "./config";
import Amplify from "@aws-amplify/core";
import { BrowserRouter } from "react-router-dom";

import { DalmaToastify } from "./components/dalma-toastify";
import { IntercomProvider } from "src/utils/intercom";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "eu-west-3",
    userPoolId: SANDBOX ? "eu-west-3_qEHDOWAEA" : "eu-west-3_fhynVElBX",
    userPoolWebClientId: SANDBOX
      ? "6gof269njcdhc4hukmhcffcl8p"
      : "5beu914l09lnoc9pioh25rc01t",
    identityPoolId: SANDBOX
      ? "eu-west-3:63df0959-b530-4f5c-9170-6da660f34591"
      : "eu-west-3:129d66a4-b9e3-4392-9648-c212de07b844",
  },
  API: {
    endpoints: [
      {
        name: "apiAuthGateway",
        region: "eu-west-3",
        endpoint: SANDBOX
          ? "https://auth.dalma.dev/dalma-blue-latest"
          : "https://auth.dalma.tech/dalmaBlue",
      },
      {
        name: "apiMySpaceGateway",
        endpoint: SANDBOX
          ? "https://myspace.dalma.dev/dalma-blue-latest"
          : "https://myspace.dalma.tech/dalmaBlue",
        region: "eu-west-3",
      },
      {
        name: "apiTelevetGateway",
        endpoint: SANDBOX
          ? "https://telehealth.dalma.dev/dalma-blue-latest"
          : "https://telehealth.dalma.tech/dalmaBlue",
        region: "eu-west-3",
      },
      {
        name: "apiClaimsGateway",
        endpoint: SANDBOX
          ? "https://claims.dalma.dev/dalma-blue-latest"
          : "https://claims.dalma.tech/dalmaBlue",
        region: "eu-west-3",
      },
    ],
  },
  Storage: {
    AWSS3: {
      region: "eu-west-3",
      bucket: SANDBOX ? "staging-dalma-blue-claims" : "prod-dalma-blue-claims",
    },
  },
});

Sentry.init({
  dsn: "https://8e936c05577d47a48822b32d84010ce2@o946967.ingest.sentry.io/6134626",
  release: "dalma-blue_app@" + process.env.REACT_APP_SHA,
  integrations: [new Integrations.BrowserTracing()],
  enabled: process.env.NODE_ENV === "production",
  environment: SANDBOX ? "dev" : "production",

  // Set tracesSampleRate to capture 100% in production
  tracesSampleRate: SANDBOX ? 0 : 1,
});

function Fallback() {
  return null;
}

async function bootstrapApp() {
  if (process.env.NODE_ENV === "development") {
    // const { worker } = await import("./mocks/browser");
    // await worker.start();
    render();
  } else {
    render();
  }
}

bootstrapApp();

function render() {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      <StrictMode>
        <IntercomProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <DalmaToastify />
        </IntercomProvider>
      </StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
