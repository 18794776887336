import { differenceInCalendarMonths } from "date-fns";
import { Form, Formik, FieldArray } from "formik";
import { useEffect, useState } from "react";
import { Button } from "src/components/button";
import { H3, Modal } from "src/components/modal";
import { Pet } from "src/types";
import { SubmitButton } from "src/components/modal-submit-button";
import { fetchContracts, newClaim } from "src/utils/backend";
import { Stack } from "src/components/stack";
import { MailIcon, FileIcon, BillIcon } from "src/components/icons";
import { InputPet } from "src/components/input-pet";
import { styled } from "@stitches/react";
import calin from "src/components/calin.png";
import { DalmaInfo } from "src/components/dalma-info";
import { Spinner } from "src/components/Spinner";
import { CoverTable } from "src/components/coverTable";
import { UploadFile } from "src/components/UploadFile";
import { formatDate } from "src/utils/date";

const GeometryFormsIcon = () => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "11.92%",
          right: "85.3%",
          top: "13.95%",
          bottom: "78.1%",
        }}
      >
        <svg
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.74072 2.46779L9.11922 8.78122L7.53627 17.1971L13.112 22.1048"
            stroke="#554BB7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "40.49%",
          right: "56.77%",
          top: "0.71%",
          bottom: "88.1%",
        }}
      >
        <svg
          width="15"
          height="31"
          viewBox="0 0 15 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.60352 1.76429L8.67556 7.35119L6.31821 15.2905L12.8009 21.1714L9.2649 29.4048"
            stroke="#FFCC00"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "79.68%",
          right: "18.59%",
          top: "16.19%",
          bottom: "77.86%",
        }}
      >
        <svg
          width="11"
          height="19"
          viewBox="0 0 11 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.90332 16.6929V7.28333L8.97534 1.99048"
            stroke="#4E68F6"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "92.58%",
          right: "6.41",
          top: "6.15%",
          bottom: "88.53%",
        }}
      >
        <svg
          width="8"
          height="17"
          viewBox="0 0 8 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.63525 15.3237L5.78891 2.19532"
            stroke="#8AE5B2"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "83.29%",
          right: "13.83%",
          top: "52.38%",
          bottom: "42.86%",
        }}
      >
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.53009 14.1429C10.7849 14.1429 13.4235 11.5099 13.4235 8.2619C13.4235 5.01394 10.7849 2.38095 7.53009 2.38095C4.27527 2.38095 1.63672 5.01394 1.63672 8.2619C1.63672 11.5099 4.27527 14.1429 7.53009 14.1429Z"
            stroke="#4E68F6"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="3 5"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "85.73%",
          right: "9.94%",
          top: "90.43%",
          bottom: "17.86%",
        }}
      >
        <svg
          width="21"
          height="31"
          viewBox="0 0 21 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.3359 28.8929L11.0852 24.7762L13.4425 15.3667L1.65576 11.25L5.19178 2.42857"
            stroke="#E24D42"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "75.92%",
          right: "21.37%",
          top: "90.05%",
          bottom: "1%",
        }}
      >
        <svg
          width="15"
          height="27"
          viewBox="0 0 15 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.0893 24.5206L12.5777 17.6881L1.51465 11.3637L6.26942 2.42703"
            stroke="#FFCC00"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        style={{
          position: "absolute",
          left: "17.29%",
          right: "79.68%",
          top: "95.43%",
          bottom: "6.19%",
        }}
      >
        <svg
          width="16"
          height="23"
          viewBox="0 0 16 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.7207 20.7095L9.38209 14.8286V6.59524L14.0968 2.47858"
            stroke="#DF3474"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

const Img = styled("img", {
  width: "116px",
  height: "107px",
  left: "-5px",
  top: "-5px",
});

export function getMonthsSince(birthdate: string) {
  return differenceInCalendarMonths(new Date(), new Date(birthdate));
}

export function formatAge(birthdate: string) {
  if (!birthdate) {
    return null;
  }
  const age = getMonthsSince(birthdate);
  const year = Math.floor(age / 12);
  const month = age % 12;
  const output = [];
  if (year > 0) {
    output.push(`${year} an${year === 1 ? "" : "s"}`);
  }
  if (month > 0) {
    output.push(`${month} mois`);
  }
  return output.join(" et ");
}

export function Urgence({
  onClose,
  openVet,
  onSubmit,
}: {
  onClose: () => void;
  openVet: () => void;
  onSubmit: () => void;
}) {
  const [state, setState] = useState(0);
  const [pets, setPets] = useState<Pet[] | null>(null);
  const [confirm, setConfirm] = useState(false);
  const [coverTable, openCoverTable] = useState<boolean>(false);
  const [selectedPet, setSelectedPet] = useState<Pet | null>(null);

  useEffect(() => {
    fetchContracts().then(setPets);
  }, []);
  if (pets === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner />
      </div>
    );
  }
  if (state === 0) {
    return (
      <Formik
        initialValues={{
          pet: null,
        }}
        validate={({ pet }) => {
          const errors: { pet?: string } = {};
          if (pet == null) {
            errors.pet = "Veuillez selectionner un animal";
          }
          return errors;
        }}
        validateOnMount
        onSubmit={async (values) => {
          setState((c) => c + 1);
          if (values && values.pet) {
            setSelectedPet(
              pets.find((pet) => pet.contract.contract_uuid === values.pet)!
            );
          }
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form style={{ marginBottom: 86 }}>
            <Stack direction="column">
              <H3>Pour quel animal ?</H3>
              {pets.map((pet) => (
                <InputPet
                  pet={pet}
                  key={pet.pet.contract_uuid}
                  petName={pet.pet.pet_name}
                  name="pet"
                  value={pet.pet.contract_uuid}
                  type={pet.pet.pet_type}
                  used={pet.coverage.health_conso > 0}
                >
                  {pet.pet.pet_type === "cat" ? "Chat " : "Chien "}
                  {pet.pet.pet_sexe === "MALE" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                      fill="#757575"
                    >
                      <path d="M16 2v2h3.586l-3.972 3.972c-1.54-1.231-3.489-1.972-5.614-1.972-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-2.125-.741-4.074-1.972-5.614l3.972-3.972v3.586h2v-7h-7zm-6 20c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 24 24"
                      fill="#757575"
                    >
                      <path d="M21 9c0-4.97-4.03-9-9-9s-9 4.03-9 9c0 4.632 3.501 8.443 8 8.941v2.059h-3v2h3v2h2v-2h3v-2h-3v-2.059c4.499-.498 8-4.309 8-8.941zm-16 0c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                    </svg>
                  )}{" "}
                  • {formatAge(pet.pet.pet_birthday)}
                </InputPet>
              ))}
              <SubmitButton isLoading={isSubmitting} isValid={isValid}>
                Suivant
              </SubmitButton>
            </Stack>
          </Form>
        )}
      </Formik>
    );
  } else if (state === 1) {
    return (
      <>
        {coverTable && <CoverageModal onClose={() => openCoverTable(false)} />}
        <Formik
          key="1"
          initialValues={{}}
          onSubmit={async () => {
            setConfirm(true);
          }}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <Stack direction="column">
                <Img src={calin} />
                <H3>Est-ce bien une urgence ?</H3>
                <span>
                  Seules les{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#4E68F6",
                      textDecoration: "underline",
                    }}
                    onClick={() => openCoverTable(true)}
                  >
                    urgences vétérinaires
                  </span>{" "}
                  sont éligibles au remboursement par dalma.blue.
                </span>
                <DalmaInfo icon={false}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      margin: "auto",
                      marginBottom: "16px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#688AB8"
                      style={{ flex: "none" }}
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.09 2.82a8 8 0 00-6.68-1.66 8 8 0 00-6.27 6.32 8.07 8.07 0 001.72 6.65A4.54 4.54 0 017 17v3a3 3 0 003 3h4a3 3 0 003-3v-2.81A5.17 5.17 0 0118.22 14a8 8 0 00-1.13-11.2v.02zM15 20a1 1 0 01-1 1h-4a1 1 0 01-1-1v-1h6v1zm1.67-7.24A7.13 7.13 0 0015 17h-2v-3a1 1 0 00-2 0v3H9a6.5 6.5 0 00-1.6-4.16 6 6 0 013.39-9.72A6 6 0 0118 9a5.89 5.89 0 01-1.33 3.76z" />
                    </svg>
                    <span
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      Un doute?
                      <br />
                      Contactez un de nos vétérinaires partenaires pour faire
                      valider votre urgence.
                    </span>
                  </div>
                  <Button
                    type="button"
                    variant="blue"
                    fullWidth
                    onClick={() => {
                      openVet();
                    }}
                  >
                    Parler à un vétérinaire
                  </Button>
                </DalmaInfo>
                <SubmitButton isLoading={isSubmitting} isValid={isValid}>
                  Oui, envoyer mes justificatifs
                </SubmitButton>
                {confirm ? (
                  <Modal
                    title="Confirmer votre demande ?"
                    onDismiss={() => setConfirm(false)}
                    nested
                  >
                    <p>
                      Attention, vous n’avez droit à vous faire rembourser
                      qu’une seule urgence par animal par an.
                    </p>
                    <p>
                      Choisissez bien ! Vous ne pourrez débloquer à nouveau le
                      fonds d’urgence de {selectedPet!.pet.pet_name} qu'à
                      l'anniversaire de votre contrat.
                      {true
                        ? null
                        : // TODO_CLAIM selectedPet.contract_birthday TODO date
                          formatDate(new Date(2022, 11, 5), "dd MMMM yyyy")}
                      .
                    </p>
                    <div>
                      <div style={{ display: "flex", margin: -5 }}>
                        <div style={{ margin: 5, flex: 1 }}>
                          <Button
                            fullWidth
                            type="button"
                            variant="outlined"
                            onClick={() => {
                              setConfirm(false);
                            }}
                          >
                            Annuler
                          </Button>
                        </div>
                        <div style={{ margin: 5, flex: 1 }}>
                          <Button
                            fullWidth
                            onClick={() => setState((c) => c + 1)}
                          >
                            Confirmer
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                ) : null}
              </Stack>
            </Form>
          )}
        </Formik>
      </>
    );
  } else if (state === 2) {
    return (
      <div style={{ marginBottom: 86 }}>
        <UploadDocuments
          nextStep={() => setState((s) => s + 1)}
          contractUuid={selectedPet!.contract.contract_uuid}
        />
      </div>
    );
  } else if (state === 3) {
    return (
      <Formik
        initialValues={{}}
        onSubmit={() => {
          onClose();
          // Wait 1s before refreshing data from backend
          setTimeout(onSubmit, 1000);
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 86, // Footer height
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  "linear-gradient(161.05deg, rgba(255, 248, 230, 0.4) 26.87%, rgba(237, 240, 254, 0.4) 97.14%), #FFFFFF",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  position: "relative",
                  padding: 25,
                }}
              >
                <GeometryFormsIcon />
                <div
                  style={{
                    backgroundColor: "#C0D8F9",
                    borderRadius: "100%",
                    height: 52,
                    width: 52,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 24,
                  }}
                >
                  <MailIcon color="#030C6C" size={25} />
                </div>
                <h1
                  style={{
                    fontFamily: "Roslindale",
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: "32px",
                  }}
                >
                  Demande envoyée !
                </h1>
                <p
                  style={{
                    margin: 0,
                    fontSize: 14,
                    lineHeight: "16px",
                  }}
                >
                  Nous allons désormais étudier votre dossier. Si tout est
                  conforme, vous recevrez le remboursement sous 10 jours.
                </p>
              </div>
            </div>
            <SubmitButton isLoading={isSubmitting} isValid={isValid}>
              Compris
            </SubmitButton>
          </Form>
        )}
      </Formik>
    );
  }
  return null;
}

type FormikProps = {
  factures: File[];
  fds: File[];
};

function UploadDocuments({
  contractUuid,
  nextStep,
}: {
  contractUuid: string;
  nextStep: () => void;
}) {
  return (
    <Formik<FormikProps>
      initialValues={{ factures: [], fds: [] }}
      onSubmit={async ({ factures, fds }) => {
        await newClaim(contractUuid, { factures, fds });
        nextStep();
      }}
      validate={({ factures, fds }) => {
        const errors: { [key: string]: string } = {};
        if (factures.length < 1) {
          errors.factures = "Veuillez ajouter une facture";
        }
        if (fds.length < 1) {
          errors.fds = "Veuillez ajouter une feuille de soin";
        }
        return errors;
      }}
      validateOnMount
    >
      {({ values, isSubmitting, isValid }) => (
        <Form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "57px 0px 18px 0px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FileIcon size={20} color="#585B66" />
              <h1
                style={{
                  margin: 0,
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "20px",
                  marginLeft: 12,
                }}
              >
                Feuilles de soin
              </h1>
            </div>
            <p
              style={{
                margin: 0,
                fontSize: 14,
                lineHeight: "22px",
                color: "#757575",
              }}
            >
              Obligatoire
            </p>
          </div>
          <FieldArray
            name="fds"
            render={(arrayHelpers) => (
              <div>
                {values.fds.map((fds: File, index: number) => (
                  <div key={index} style={{ marginBottom: 16 }}>
                    <UploadFile
                      file={fds}
                      onRemove={() => arrayHelpers.remove(index)}
                    />
                  </div>
                ))}
                <UploadFile
                  id="fds"
                  onAdd={(file: File) => arrayHelpers.insert(0, file)}
                />
              </div>
            )}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "42px 0px 18px 0px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BillIcon size={20} color="#585B66" />
              <h1
                style={{
                  margin: 0,
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "20px",
                  marginLeft: 12,
                }}
              >
                Facture(s)
              </h1>
            </div>
            <p
              style={{
                margin: 0,
                fontSize: 14,
                lineHeight: "22px",
                color: "#757575",
              }}
            >
              Obligatoire
            </p>
          </div>
          <FieldArray
            name="factures"
            render={(arrayHelpers) => (
              <div>
                {values.factures.map((facture: File, index: number) => (
                  <div key={index} style={{ marginBottom: 16 }}>
                    <UploadFile
                      file={facture}
                      onRemove={() => arrayHelpers.remove(index)}
                    />
                  </div>
                ))}
                <UploadFile
                  id="factures"
                  onAdd={(file: File) => arrayHelpers.insert(0, file)}
                />
              </div>
            )}
          />
          <SubmitButton isLoading={isSubmitting} isValid={isValid}>
            Envoyer la demande de remboursement
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
}

export function CoverageModal({ onClose }: { onClose: () => void }) {
  return (
    <Modal title="Tableau de couverture" onDismiss={() => onClose()}>
      <div style={{ marginTop: 26 }}>
        <CoverTable
          covered
          items={[
            "Fracture",
            "Torsion de l'estomac",
            "Détresse respiratoire",
            "Épillet infecté",
            "Arrêt cardiaque",
            "Chat parachute",
            "Calculs urinaires",
            "Oedèmes",
            "Accident de la route",
            "AVC",
          ]}
          defaultNumberOfItems={10}
          title="Urgences couvertes"
          subTitle="Quand la vie de votre animal et/ou son caractère fonctionnel est en danger et qu’il doit être pris en charge très rapidement."
          moreItemLabel="Voir toutes les urgences couvertes"
        />
      </div>
      <div style={{ marginTop: 32 }}>
        <CoverTable
          covered={false}
          items={[
            "Soins préventifs",
            "Rééducation",
            "Opérations prévues de longue date",
          ]}
          defaultNumberOfItems={3}
          title="Ce que nous ne couvrons pas"
          subTitle="Les soins liés à des situations non urgentes ne sont pas couverts."
          moreItemLabel="Voir toutes les situations non couvertes"
        />
      </div>
    </Modal>
  );
}
