import { Children, ReactNode, useEffect, useReducer, useState } from "react";
import { Card } from "src/components/card";
import {
  DiscussionsIcon,
  DownArrow,
  DownloadFileIcon,
  RefundIcon,
} from "src/components/icons";
import { H3, Modal } from "src/components/modal";
import { ProfileButton } from "src/components/navbar";
import { Reimbursement } from "src/components/reimbursement";
import { Stack } from "src/components/stack";
import { css, styled } from "src/style/theme";
import { Claim, Profile } from "src/types";
import { fetchClaims } from "src/utils/backend";
import { Urgence } from "./urgence";
import { Vet } from "./vet";
import { legal } from "../config";

const homeRow = css({
  display: "flex",
  flexDirection: "column",
  margin: -5,

  "& > *": {
    margin: 5,
  },

  "@bp1": {
    flexDirection: "row",
    flexWrap: "wrap",

    "& > *": {
      flex: "1 1 300px",
      maxWidth: 450,
    },
  },
});

function HomeRow({ children }: { children: ReactNode }) {
  return (
    <div>
      <div className={homeRow()}>
        {Children.map(children, (child, index) => (
          <div key={index}>{child}</div>
        ))}
      </div>
    </div>
  );
}

const PageTitle = styled("h1", {
  fontSize: 24,
  lineHeight: "32px",
  fontFamily: "Roslindale",

  "@bp1": {
    fontSize: 32,
    lineHeight: "36px",
  },
});

const PageSubTitle = styled("p", {
  fontSize: 12,
  lineHeight: "16px",

  "@bp1": {
    fontSize: 16,
    lineHeight: "24px",
  },
});

const profileWrapper = css({
  display: "none",

  "@bp1": {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 72,
    top: 92,
  },
});

export function Home({ profile }: { profile: Profile }) {
  const [reimbursement, setModal] = useState(false);
  const [vet, openVet] = useState(false);

  const [cacheKey, forceRefresh] = useReducer(() => ({}), {});

  const [claims, setClaims] = useState<Claim[] | null>(null);

  useEffect(() => {
    fetchClaims().then(setClaims);
  }, [cacheKey]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle>Bonjour {profile.firstname} 👋</PageTitle>
        <div className={profileWrapper()}>
          <ProfileButton profile={profile} />
          <div style={{ marginLeft: 12 }}>
            <DownArrow color="#757575" size={10} />
          </div>
        </div>
      </div>
      <PageSubTitle>Comment pouvons-nous vous aider ?</PageSubTitle>
      <HomeRow>
        <Card
          onClick={() => openVet(true)}
          title="Parler à un vétérinaire"
          subtitle="Faire valider une situation d’urgence ou poser une question."
          iconBgColor="#E9F3FD"
          icon={<DiscussionsIcon color="#4E68F6" size={20} />}
        />
        <Card
          onClick={() => setModal(true)}
          title="Me faire rembourser"
          subtitle="Activer mon fonds d'urgence."
          iconBgColor="#FAEEE6"
          icon={<RefundIcon color="#FE6F18" size={20} />}
        />
        <a
          href={legal.v1.feuille_de_soins}
          target="_blank"
          rel="noreferrer"
          download
          style={{ textDecoration: "none" }}
        >
          <Card
            onClick={() => {}}
            title="Télécharger la feuille de soins"
            subtitle="Préparez votre premier remboursement"
            iconBgColor="#F8F8FA"
            icon={<DownloadFileIcon color="#868896" size={20} />}
          />
        </a>
      </HomeRow>
      <div style={{ height: 20 }} />
      <Wrapper>
        <Stack css={{ $$margin: "20px" }}>
          <H3>Votre historique</H3>
          <Stack css={{ $$margin: "16px" }}>
            {claims
              ? claims.map((claim) => (
                  <Reimbursement
                    key={claim.claim_id}
                    claim={claim}
                    email={profile.email}
                  />
                ))
              : null}
          </Stack>
        </Stack>
      </Wrapper>
      {vet ? (
        <Modal title="Parler à un vétérinaire" onDismiss={() => openVet(false)}>
          <Vet profile={profile} onClose={() => openVet(false)} />
        </Modal>
      ) : null}
      {reimbursement ? (
        <Modal
          title="Demande de remboursement"
          onDismiss={() => setModal(false)}
        >
          <Urgence
            onClose={() => setModal(false)}
            openVet={() => openVet(true)}
            onSubmit={() => setTimeout(forceRefresh, 0)}
          />
        </Modal>
      ) : null}
    </>
  );
}
const Wrapper = styled("div", {
  padding: 24,

  /* Electrique/50 */
  background: "#EDF0FE",
  borderRadius: 16,
  maxWidth: 1370,
});
