import { ComponentProps, ReactNode, useEffect } from "react";
import { useField } from "formik";
import { styled } from "@stitches/react";

import { css, theme } from "src/style/theme";

type InputProps = {
  // Name and label is required
  name: string;
} & Omit<ComponentProps<"input">, "type">;

const Label = styled("label", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const checkbox = css({
  display: "inline-block",
  cursor: "pointer",
  appearance: "none",
  flex: "none",
  marginRight: 10,
  fontSize: 16,
  width: "1em",
  height: "1em",
  border: "1px solid gray",
  borderRadius: 3,
  background: theme.colors.white,

  "&:checked": {
    $$color: theme.colors.electric,
    background: "$$color",
    borderColor: "$$color",
    backgroundImage: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linejoin='round' stroke-width='3' d='M3 9l5 5l10-10'/></svg>")`,
  },
});

const InputCheckbox = ({
  children,
  name,
  onChange,
}: { children: ReactNode } & InputProps) => {
  const [field] = useField({ name, type: "checkbox" });
  useEffect(() => {});
  return (
    <Label>
      <input
        type="checkbox"
        className={checkbox()}
        {...field}
        onChange={(e) => {
          field.onChange(e);
          if (typeof onChange === "function") {
            onChange(e);
          }
        }}
      />
      {children}
    </Label>
  );
}

export default InputCheckbox;
