import { ReactNode } from "react";
import { theme, styled } from "src/style/theme";

const Wrapper = styled("div", {
  padding: 16,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  border: `1px solid ${theme.colors.blueBackGround}`,
  background: theme.colors.blueBackGround,
  lineHeight: "22px",
  borderRadius: 16,
  boxSizing: "border-box",
  fontSize: 14,
  "@bp1": {
    fontSize: 16,
  },
});

export function DalmaText({ children }: { children: ReactNode }) {
  return <Wrapper>{children}</Wrapper>;
}
