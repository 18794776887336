import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { Formik, Form } from "formik";

import { Input } from "src/components/input";
import { Button } from "src/components/button";

import InputCheckbox from "src/components/input-checkbox";
import AuthForm from "src/components/authForm";

import { EMAIL_REGEX } from "src/utils/password";
import { resetPassword } from "src/utils/backend";
import { AuthState } from "src/auth";

type FormValues = {
  email: string;
  password: string;
  stayConnected: boolean;
};

function validate(values: FormValues) {
  const errors: Partial<FormValues> = {};

  if (!values.email) {
    errors.email = "Obligatoire";
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email invalide";
  }
  if (!values.password) {
    errors.password = "Obligatoire";
  }
  return errors;
}

export function Login({ auth }: { auth: AuthState }) {
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (auth === "signIn") {
      navigate(from, { replace: true });
    }
  }, [auth, navigate, from]);

  const onConnect = async (values: FormValues) => {
    const { email, password } = values;
    await Auth.signIn(email, password).then(
      (user) => {
        // navigate(from, { replace: true }); ???
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          navigate("/first-connection", {
            replace: true,
            state: {
              email: email,
              tmpPassword: password,
            },
          });
        }
      },
      (error) => {
        if (error.code === "NotAuthorizedException") {
          if (
            error.message ===
            "Temporary password has expired and must be reset by an administrator."
          ) {
            resetPassword(email);
          } else {
            alert("Email et mot de passe incorrect.");
          }
        } else if (error.code === "CodeMismatchException") {
          alert("Le code de vérification est incorrect.");
        } else if (error.code === "ExpiredCodeException") {
          alert("Le code de vérification a déjà été utilisé.");
        } else if (error.code === "LimitExceededException") {
          alert(
            "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co"
          );
        } else if (error.code === "InvalidParameterException") {
          alert(
            "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et miniscule, caractères spéciaux"
          );
        } else {
          alert(
            "Une erreur est survenue. Verifiez que vous saisi le bon 'code de vérification' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et miniscule, caractères spéciaux"
          );
        }
      }
    );
  };

  return (
    <AuthForm
      title="Bienvenue chez Dalma.blue !"
      subTitle="Connexion à votre espace client"
      previousPage="https://www.dalma.blue"
      webLink
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
          stayConnected: false,
        }}
        validate={validate}
        validateOnMount
        onSubmit={onConnect}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Input label="Adresse email" name="email" type="email" />
            </div>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Input label="Mot de passe" name="password" type="password" />
            </div>
            <div style={{ display: "flex", marginBottom: "23px" }}>
              <Button
                style={{ width: "100%", height: 50 }}
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Envoyer
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              <InputCheckbox name="stayConnected">
                <p style={{ color: "#616161", fontWeight: 400 }}>
                  Rester connecté(e)
                </p>
              </InputCheckbox>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    color: "#4E68F6",
                    textDecoration: "underline",
                  }}
                >
                  <Link to="/forgot-password">Mot de passe oublié ?</Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthForm>
  );
}
