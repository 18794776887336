import React from "react";
import { styled, theme } from "src/style/theme";
import { CheckIcon, ExclamationIcon } from "./icons";
import { formatPrice } from "./profile";

const Card = styled("div", {
  padding: 16,
  border: "1px solid #DEDFE6",
  borderRadius: 12,
  display: "flex",
  justifyContent: "space-between",

  variants: {
    top: {
      true: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    hideTopBorderBottomWidth: {
      true: {
        borderBottomWidth: 0,
      },
    },
    middle: {
      true: {
        borderRadius: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
      },
    },
    bottom: {
      true: {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
      },
    },
    hideBottomBorderTopWidth: {
      true: {
        borderTopWidth: 0,
      },
    },
  },
});

const CardTitle = styled("h1", {
  margin: 0,
  fontSize: 14,
  lineHeight: "16px",
  color: theme.colors.electric900,
});

const CardText = styled("p", {
  margin: 0,
  fontSize: 12,
  lineHeight: "14px",
  color: theme.colors.gray600,
  fontWeight: "bold",
});

const MissingDocument = styled("div", {
  display: "flex",
  float: "left",
  backgroundColor: theme.colors.accentOrangeLight,
  borderRadius: 6,
  padding: "4px 6px",
  alignItems: "center",

  "> p": {
    margin: "0px 0px 0px 5px",
    color: theme.colors.accentOrange,
    fontSize: 12,
    lineHeight: "14px",
  },
});

export const ReimbursementCard = ({
  children,
  price = 0,
  reimbursementPercentage = 0,
  leftRender,
  rightRender,
  placement = "none",
  hideTopBorderBottomWidth = false,
  hideBottomBorderTopWidth = false,
  missingDocument = false,
  notCovered = false,
}: {
  children?: React.ReactNode;
  price?: number;
  reimbursementPercentage?: number;
  leftRender?: React.ReactNode;
  rightRender?: React.ReactNode;
  placement?: "top" | "middle" | "bottom" | "none";
  hideTopBorderBottomWidth?: boolean;
  hideBottomBorderTopWidth?: boolean;
  missingDocument?: boolean;
  notCovered?: boolean;
}) => {
  if (price < 0 || reimbursementPercentage < 0) {
    return null;
  }
  return (
    <Card
      top={Boolean(placement === "top")}
      middle={Boolean(placement === "middle")}
      bottom={Boolean(placement === "bottom")}
      hideTopBorderBottomWidth={hideTopBorderBottomWidth}
      hideBottomBorderTopWidth={hideBottomBorderTopWidth}
    >
      {leftRender && rightRender ? (
        <>
          {leftRender}
          {rightRender}
        </>
      ) : (
        <>
          <div>
            <CardTitle>{children}</CardTitle>
            <CardText>{formatPrice(price * 100)}€</CardText>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {missingDocument ? (
              <MissingDocument>
                <ExclamationIcon color="#FE6F18" size={13} />
                <p>Document manquant</p>
              </MissingDocument>
            ) : notCovered ? (
              <div>
                <CardText>Non couvert</CardText>
              </div>
            ) : reimbursementPercentage > 0 ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <CheckIcon color="#00704F" size={15} />
                  <CardText
                    style={{
                      fontSize: 18,
                      lineHeight: "20px",
                      color: "#00704F",
                      marginLeft: 5,
                    }}
                  >
                    {formatPrice(price * reimbursementPercentage)}€
                  </CardText>
                </div>
                {price > 0 && (
                  <CardText>
                    Remboursé à{" "}
                    {reimbursementPercentage.toFixed(2).replace(".", ",")}%
                  </CardText>
                )}
              </div>
            ) : (
              <div>
                <CardText>Non remboursé</CardText>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};
