import "@reach/dialog/styles.css";
import dalmaModalImg from "./dalma-modal.png";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { ReactNode } from "react";
import { css, styled, theme } from "src/style/theme";
import { VariantProps } from "@stitches/react";

const overlay = css({
  "&[data-reach-dialog-overlay]": {
    position: "fixed",
    inset: 0,
    background: "rgba(0, 0, 0, 0.2)",
    zIndex: 5,
  },

  variants: {
    transparentOverlay: {
      true: {
        "&[data-reach-dialog-overlay]": {
          background: "transparent",
        },
      },
    },
  },
});

function svg2url(str: TemplateStringsArray, ...rest: string[]) {
  let out = str[0];
  for (const [i, elt] of rest.entries()) {
    out += elt + str[i + 1];
  }
  return (
    `url("data:image/svg+xml;utf-8,` +
    out.replaceAll('"', "'").replaceAll("#", "%23").replaceAll("\n", "") +
    `")`
  );
}

const svg1 = svg2url`<svg width="310" height="256" viewBox="0 0 310 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M310 97.3235C309.772 111.074 306.955 123.556 303.514 135.917C300.073 148.277 295.749 160.27 293.131 172.783C291.334 181.324 289.797 189.926 288.122 198.482C286.173 208.566 283.889 218.603 279.307 227.816C271.694 243.263 259.606 253.041 242.097 255.455C230.419 257.059 219.168 254.89 208.024 251.971C195.95 248.809 183.984 245.234 171.956 241.888C164.028 239.714 155.893 238.382 147.687 237.915C136.086 237.213 124.469 236.876 112.868 236.219C100.216 235.501 87.5948 234.126 75.8717 229.008C62.8123 223.308 50.5328 215.955 39.3319 207.129C30.3015 200.17 22.3755 191.876 15.8247 182.531C7.32621 170.218 2.08711 155.939 0.59977 141.035C-1.53171 120.867 2.12226 101.724 9.90218 83.191C18.9979 60.747 34.7865 41.6742 55.1049 28.5859C63.7983 22.9482 72.9637 18.1508 83.3623 16.2715C91.5533 14.7437 99.7138 15.3548 107.89 16.2715C119.811 17.5549 131.579 20.1217 143.592 20.6412C154.698 21.0811 165.793 19.5289 176.356 16.0577C188.094 12.3145 199.528 7.70038 211.373 4.0794C219.389 1.36891 227.792 -0.00904446 236.251 4.4672e-05C247.882 0.305613 258.053 4.73635 267.401 11.3672C278.256 19.0064 286.264 29.2582 292.704 40.7476C299.598 53.2259 304.762 66.5907 308.051 80.4714C309.343 85.9952 309.997 91.6497 310 97.3235Z" fill="#4E68F619"/>
</svg>`;

const svg2 = svg2url`<svg width="304" height="256" viewBox="0 0 304 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M292.026 153.968C286.306 182.863 289.598 216.637 267.668 236.733C245.423 257.117 211.192 256.115 180.748 255.938C151.903 255.77 124.721 248.318 98.8772 235.752C69.8359 221.632 39.6449 206.918 23.3798 179.422C5.54191 149.267 -8.4989 110.632 6.04753 78.8221C20.4246 47.3829 62.1411 41.2924 93.5806 25.91C117.455 14.229 140.407 -0.850373 167.073 0.0374513C192.511 0.884408 212.709 18.0833 234.725 30.6094C258.182 43.9552 288.359 51.2394 299.601 75.4408C310.994 99.9682 297.265 127.51 292.026 153.968Z" fill="#4E68F619"/>
</svg>`;

const content = css({
  "&[data-reach-dialog-content]": {
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,

    "& > div": {
      padding: 24,
      overflowY: "auto",
      height: "100%",
    },
    width: "unset",
    margin: "unset",
  },

  "@bp1": {
    "&[data-reach-dialog-content]": {
      left: "unset",
      width: 605,
      maxWidth: "80%",
    },
  },

  variants: {
    nested: {
      true: {
        "&[data-reach-dialog-content]": {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          top: "unset",
        },
      },
    },
    dalma: {
      true: {
        "&[data-reach-dialog-content]": {
          backgroundColor: "#F1F7FF",
          backgroundImage: `${svg1}, ${svg2}`,
          backgroundPosition: "-20% -10%, 120% 110%",
          backgroundSize: "60% auto, 60% auto",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
        },
      },
    },
  },
});

const title = css({
  fontFamily: "Roslindale",
  /* Header/H3 */
  fontSize: 24,
  lineHeight: "32px",

  color: theme.colors.gray900,
  margin: 0,

  variants: {
    size: {
      h4: {
        fontSize: 20,
        lineHeight: "28px",
      },
      h5: {
        fontSize: 18,
        lineHeight: "24px",
      },
    },
  },
});

export function H3({
  children,
  size,
}: { children: ReactNode } & VariantProps<typeof title>) {
  return <div className={title({ size })}>{children}</div>;
}

export const ModalSeparator = styled("hr", {
  margin: "32px -24px",
  border: "none",
  borderTop: `1px solid ${theme.colors.gray300}`,
});

function ModalCloseButton({ onDismiss }: Pick<Props, "onDismiss">) {
  return (
    <button
      onClick={onDismiss}
      style={{
        flex: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        width: 44,
        height: 44,
        cursor: "pointer",

        /* Blue/Bright */
        background: "#DBEBFF",
        borderRadius: 24,
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Fermer"
      >
        <path
          d="M11.175 10.0002L16.425 4.75849C16.5819 4.60157 16.6701 4.38874 16.6701 4.16682C16.6701 3.9449 16.5819 3.73207 16.425 3.57515C16.2681 3.41823 16.0552 3.33008 15.8333 3.33008C15.6114 3.33008 15.3986 3.41823 15.2417 3.57515L9.99999 8.82515L4.75832 3.57515C4.6014 3.41823 4.38857 3.33008 4.16666 3.33008C3.94474 3.33008 3.73191 3.41823 3.57499 3.57515C3.41807 3.73207 3.32991 3.9449 3.32991 4.16682C3.32991 4.38874 3.41807 4.60157 3.57499 4.75849L8.82499 10.0002L3.57499 15.2418C3.49688 15.3193 3.43489 15.4115 3.39258 15.513C3.35027 15.6146 3.32849 15.7235 3.32849 15.8335C3.32849 15.9435 3.35027 16.0524 3.39258 16.154C3.43489 16.2555 3.49688 16.3477 3.57499 16.4252C3.65246 16.5033 3.74463 16.5653 3.84618 16.6076C3.94773 16.6499 4.05665 16.6717 4.16666 16.6717C4.27667 16.6717 4.38559 16.6499 4.48714 16.6076C4.58869 16.5653 4.68085 16.5033 4.75832 16.4252L9.99999 11.1752L15.2417 16.4252C15.3191 16.5033 15.4113 16.5653 15.5128 16.6076C15.6144 16.6499 15.7233 16.6717 15.8333 16.6717C15.9433 16.6717 16.0523 16.6499 16.1538 16.6076C16.2554 16.5653 16.3475 16.5033 16.425 16.4252C16.5031 16.3477 16.5651 16.2555 16.6074 16.154C16.6497 16.0524 16.6715 15.9435 16.6715 15.8335C16.6715 15.7235 16.6497 15.6146 16.6074 15.513C16.5651 15.4115 16.5031 15.3193 16.425 15.2418L11.175 10.0002Z"
          fill="#144283"
        />
      </svg>
    </button>
  );
}

type Props = {
  children: ReactNode;
  onDismiss: () => void;
  nested?: boolean;
  title: string;
  formattedTitle?: ReactNode;
  subtitle?: string;
  transparentOverlay?: boolean;
};

const st = css({
  fontSize: 14,
  lineHeight: "16px",

  letterSpacing: "-0.02em",
  marginTop: 4,

  color: theme.colors.gray600,
});

export function Modal({
  children,
  onDismiss,
  nested,
  title,
  formattedTitle = title,
  subtitle,
}: Props) {
  return (
    // We disable Focus Lock to allow intercom to work even when modals are open
    <DialogOverlay
      className={overlay()}
      onDismiss={onDismiss}
      dangerouslyBypassFocusLock
    >
      <DialogContent aria-label={title} className={content({ nested })}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <div>
              <H3>{formattedTitle}</H3>
              {subtitle ? <span className={st()}>{subtitle}</span> : null}
            </div>
            <ModalCloseButton onDismiss={onDismiss} />
          </div>
          {children}
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}

export function DalmaModal({
  children,
  onDismiss,
  title,
  transparentOverlay = false,
}: Omit<Props, "nested">) {
  return (
    <DialogOverlay
      className={overlay({ transparentOverlay })}
      onDismiss={onDismiss}
    >
      <DialogContent aria-label={title} className={content({ dalma: true })}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
              position: "absolute",
              right: 24,
            }}
          >
            <ModalCloseButton onDismiss={onDismiss} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              flex: 1,
            }}
          >
            <img
              src={dalmaModalImg}
              style={{ width: "160px", height: "auto" }}
              alt=""
            />

            <H3 size="h4">{title}</H3>
            <p
              style={{
                fontSize: 14,
                lineHeight: "22px",

                textAlign: "center",
              }}
            >
              {children}
            </p>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}
