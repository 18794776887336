import { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { Input } from "src/components/input";
import { Button } from "src/components/button";
import AuthForm from "src/components/authForm";

import {
  EMAIL_REGEX,
  NotValidPasswordIcon,
  passwordRequirements,
  PasswordValidator,
  ValidPasswordIcon,
} from "src/utils/password";

type FormValues = {
  email: string;
  tmpPassword: string;
  newPassword: string;
};

type ValidateFormValues = {
  email: string;
  tmpPassword: string;
  newPassword: string[];
};

function validate(values: FormValues) {
  const errors: Partial<ValidateFormValues> = {};

  if (!values.email) {
    errors.email = "Vous de saisir un email";
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email invalide";
  }

  if (!values.tmpPassword) {
    errors.tmpPassword = "Mot de passe temporaire obligatoire";
  }

  if (!values.newPassword) {
    errors.newPassword = ["Veuillez renseigner le nouveau mot de passe"];
  } else {
    const newPasswordError = PasswordValidator(values.newPassword);

    if (newPasswordError.length > 0) {
      errors.newPassword = newPasswordError;
    }
  }
  return errors;
}

export function FirstConnection({ nextPage = "/" }) {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email") || location.state?.email || "";
  const tmpPwd =
    searchParams.get("tmpPwd") || location.state?.tmpPassword || "";

  useEffect(() => {
    if (!email || !tmpPwd) {
      navigate("/login", { replace: true });
    }
  }, [email, tmpPwd, navigate]);

  const firstPassword = async (values: FormValues) => {
    // console.log("loginState", loginState);
    await Auth.signIn(values.email, values.tmpPassword)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            values.newPassword, // the new password
            // OPTIONAL, the required attributes
            {
              email: values.email,
            }
          )
            .then(() => {
              // console.log(user);
              navigate(nextPage, { replace: true });
            })
            .catch((err) => {
              console.log("error :: ", err);
              if (err.code === "NotAuthorizedException") {
                toast("Email ou mot de passe temporaire incorrect.", {
                  type: toast.TYPE.WARNING,
                  autoClose: 5000,
                });
              } else if (err.code === "CodeMismatchException") {
                toast("Le code de vérification est incorrect.", {
                  type: toast.TYPE.WARNING,
                  autoClose: 5000,
                });
              } else if (err.code === "ExpiredCodeException") {
                toast("Le code de vérification a déjà été utilisé.", {
                  type: toast.TYPE.WARNING,
                  autoClose: 5000,
                });
              } else if (err.code === "LimitExceededException") {
                toast(
                  "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co",
                  { type: toast.TYPE.WARNING, autoClose: 5000 }
                );
              } else if (err.code === "InvalidParameterException") {
                toast(
                  "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
                  { type: toast.TYPE.WARNING, autoClose: 5000 }
                );
              } else {
                toast(
                  "Une erreur est survenue. Verifiez que vous saisi le 'mot de passe temporaire' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
                  { type: toast.TYPE.ERROR, autoClose: 10000 }
                );
              }
            });
        } else {
          // other situations
        }
      })
      .catch((err) => {
        console.log("err :: ", err);
        if (err.code === "NotAuthorizedException") {
          toast("Email ou mot de passe temporaire incorrect.", {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });
        } else if (err.code === "CodeMismatchException") {
          toast("Le code de vérification est incorrect.", {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });
        } else if (err.code === "ExpiredCodeException") {
          toast("Le code de vérification a déjà été utilisé.", {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });
        } else if (err.code === "LimitExceededException") {
          toast(
            "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co",
            { type: toast.TYPE.WARNING, autoClose: 5000 }
          );
        } else if (err.code === "InvalidParameterException") {
          toast(
            "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
            { type: toast.TYPE.WARNING, autoClose: 5000 }
          );
        } else {
          toast(
            "Une erreur est survenue. Verifiez que vous saisi le 'mot de passe temporaire' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
            { type: toast.TYPE.ERROR, autoClose: 10000 }
          );
        }
      });
  };

  return (
    <AuthForm
      title="Créer un mot de passe"
      subTitle="Veuillez configurer votre 1er mot de passe"
      previousPage="/login"
    >
      <Formik
        validate={validate}
        validateOnMount
        initialValues={{
          email: email,
          tmpPassword: tmpPwd,
          newPassword: "",
        }}
        onSubmit={firstPassword}
      >
        {({ values, errors, isSubmitting, isValid }) => (
          <Form>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Email"
                name="email"
                type="email"
              />
            </div>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Mot de passe temporaire"
                name="tmpPassword"
                type="text"
              />
            </div>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Nouveau mot de passe"
                name="newPassword"
                type="password"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#F5F5F5",
                padding: "10px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#616161", margin: "0px 0px 3px 0px" }}>
                {"Votre nouveau mot de passe doit contenir au moins :"}
              </p>
              {passwordRequirements.map((data, index) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    {(errors.newPassword &&
                      errors.newPassword.includes(data.requirement)) ||
                    !values.newPassword ? (
                      <NotValidPasswordIcon />
                    ) : (
                      <ValidPasswordIcon />
                    )}
                    <p style={{ color: "#616161", margin: "0px 0px 0px 10px" }}>
                      {data.text}
                    </p>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex", marginBottom: "23px" }}>
              <Button
                style={{ marginTop: "4px", width: "100%" }}
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Envoyer
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthForm>
  );
}
