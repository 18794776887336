import { ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthState, useAuth } from "./auth";
import { ForgotPassword } from "./pages/Login/forgotPassword";
import { ChangePassword } from "./pages/Login/changePassword";
import { FirstConnection } from "./pages/Login/firstConnection";
import { Login } from "./pages/Login/login";
import { Spinner } from "./components/Spinner";
import { Navbar } from "./components/navbar";
import { Home } from "./pages/home";
import { fetchProfile } from "./utils/backend";
import { Profile } from "./types";
import { Intercom } from "./components/modal-submit-button";
import { SANDBOX } from "src/config";
import { Sandbox } from "src/components/Sandbox";

function RequireAuth({
  auth,
  children,
}: {
  auth: AuthState;
  children: ReactNode;
}) {
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (auth === "loading") {
    return <Spinner />;
  }

  return <>{children}</>;
}
export function App() {
  const auth = useAuth();
  return (
    <>
      {SANDBOX && <Sandbox />}
      <Routes>
        <Route path="/login" element={<Login auth={auth} />} />
        <Route path="/first-connection" element={<FirstConnection />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />

        <Route
          path="*"
          element={
            <RequireAuth auth={auth}>
              <ConnectedApp />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

function ConnectedApp() {
  const [profile, setProfile] = useState<Profile | null>(null);
  useEffect(() => {
    fetchProfile().then(setProfile);
  }, []);

  if (profile == null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Navbar profile={profile}>
        <Routes>
          <Route path="/" element={<Home profile={profile} />} />
        </Routes>
      </Navbar>
      <div style={{ position: "fixed", bottom: 0, right: 0 }}>
        <Intercom />
      </div>
    </>
  );
}
