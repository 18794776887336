import "@reach/menu-button/styles.css";
import { ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { signOut } from "src/auth";
import { css, theme } from "src/style/theme";
import logoWhite from "./logo-white.svg";
import logoBlue from "./logo-blue.svg";
import menu from "./menu.svg";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { Profile } from "src/types";
import NavLinkButton from "./navlinkButton";
import { HomeIcon, InfoIcon, MedalIcon } from "./icons";
import { ProfileModal } from "./profile";
import { ContractsModal } from "./contracts";
import { MARKETING } from "src/config";

const navbarWrapper = css({
  display: "flex",
  flexDirection: "column",

  justifyContent: "stretch",
  alignItems: "stretch",
  height: "100%",
  "@bp1": {
    flexDirection: "row",
  },
});

const navbarMobile = css({
  background: theme.colors.electric,
  padding: 24,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  zIndex: 1,
  "@bp1": {
    display: "none",
  },
});

const navbarButton = css({
  appearance: "none",
  border: "none",
  background: "none",
  marginRight: 24,
});

const navbarDesktop = css({
  display: "none",

  width: 300,
  padding: 24,

  background:
    "linear-gradient(0deg, rgba(197, 205, 252, 0.08), rgba(197, 205, 252, 0.08)), #FFFFFF",

  /* Blue/100 */
  border: "1px solid #E9F3FD",

  "@bp1": {
    display: "flex",
    flexDirection: "column",
  },
});

const DURATION = "0.15s";

const navbarMenu = css({
  margin: 0,
  listStyle: "none",
  background: "white",
  position: "fixed",
  visibility: "hidden",
  width: "90vw",
  padding: 0,
  borderRadius: 8,
  left: "-100vw",
  top: "80px",
  transition: `transform ${DURATION} ease-in, visibility 0s ${DURATION} linear`,
  transform: "translateX(0)",

  variants: {
    isOpen: {
      true: {
        transition: `transform ${DURATION} ease-out`,

        visibility: "visible",
        transform: "translateX(105vw)",
      },
    },
  },
});

const profileButton = css({
  cursor: "pointer",
  appearance: "none",
  $$size: "40px",
  width: "$$size",
  height: "$$size",
  borderRadius: "$$size",
  border: "none",
  background: theme.colors.blue200,
  color: theme.colors.blue800,
});

const navbarBackdrop = css({
  variants: {
    isOpen: {
      true: {
        position: "fixed",
        inset: 0,
        background: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
});

const desktopLinks = css({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

const content = css({
  $$padding: "24px",
  padding: "$$padding $$padding 0 $$padding",
  overflowY: "auto",
  position: "relative",

  "&::after": {
    content: "",
    display: "block",
    height: "$$padding",
  },

  "@bp1": {
    $$padding: "72px",
    flex: 1,
  },
});

const menuList = css({
  position: "relative",
  zIndex: 10,

  borderRadius: 5,
  whiteSpace: "nowrap",
  border: "solid 1px hsla(0, 0%, 0%, 0.25)",
  background: "hsla(0, 100%, 100%, 0.99)",
  outline: "none",
  padding: "1rem 0",
  fontSize: "85%",
});

const menuItem = css({});

export function ProfileButton({ profile }: { profile: Profile }) {
  const [modal, setModal] = useState<null | "profile" | "contracts">(null);
  return (
    <>
      <Menu>
        <MenuButton className={profileButton()}>
          {profile.firstname?.slice(0, 1)}
          {profile.lastname?.slice(0, 1)}
        </MenuButton>
        <MenuList className={menuList()}>
          <MenuItem className={menuItem()} onSelect={() => setModal("profile")}>
            Mon profil
          </MenuItem>
          <MenuItem
            className={menuItem()}
            onSelect={() => setModal("contracts")}
          >
            Mes contrats
          </MenuItem>
          <MenuItem className={menuItem()} onSelect={() => signOut()}>
            Se deconnecter
          </MenuItem>
        </MenuList>
      </Menu>
      {modal === "profile" ? (
        <ProfileModal
          onDismiss={() => {
            setModal(null);
          }}
          profile={profile}
        />
      ) : modal === "contracts" ? (
        <ContractsModal
          onDismiss={() => {
            setModal(null);
          }}
        />
      ) : null}
    </>
  );
}

export function Navbar({
  profile,
  children,
}: {
  profile: Profile;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  const NavList = [
    {
      link: "/",
      label: "Mon espace",
      icon: (
        <HomeIcon
          size={20}
          color={location.pathname === "/" ? "#4E68F6" : "#616161"}
        />
      ),
    },
    {
      link: `${MARKETING}/veterinaires`,
      label: "Ma télérégulation",
      icon: <InfoIcon size={20} color={"#616161"} />,
    },
    {
      link: `${MARKETING}/urgences`,
      label: "Mon fonds d’urgence",
      icon: <MedalIcon size={20} color={"#616161"} />,
    },
  ];
  return (
    <div className={navbarWrapper()}>
      <div className={navbarMobile()}>
        <button className={navbarButton()} onClick={() => setIsOpen((v) => !v)}>
          <img src={menu} alt="menu" />
        </button>
        <Link to="/">
          <img src={logoWhite} alt="Dalma.blue" />
        </Link>
        <div
          className={navbarBackdrop({ isOpen })}
          onClick={() => setIsOpen(false)}
        />
        <ul className={navbarMenu({ isOpen })}>
          <div style={{ padding: 16 }}>
            {NavList.map(
              (
                nav: { link: string; label: string; icon: React.ReactNode },
                index: number
              ) => {
                return (
                  <li key={index}>
                    <NavLinkButton
                      label={nav.label}
                      link={nav.link}
                      icon={nav.icon}
                    />
                  </li>
                );
              }
            )}
          </div>
          {/* Sponsorship */}
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#FAFAFA",
              padding: "28px 32px",
              border: "1px solid #EEEEEE",
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderBottomWidth: 0,
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <div
              style={{ marginRight: 14, display: "flex", alignItems: "center" }}
            >
              <HeartIcon size={20} color="#616161" />
            </div>
            <p
              style={{
                margin: 0,
                color: "#616161",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Parrainer mes amis<b>{profile.raf_code}</b>}
            </p>
          </div> */}
        </ul>
        <div style={{ flex: 1 }} />
        <ProfileButton profile={profile} />
      </div>
      <div className={navbarDesktop()}>
        <Link to="/">
          <img
            src={logoBlue}
            alt="Dalma.blue"
            style={{
              width: 162,
              marginBottom: 48,
              display: "block",
              cursor: "pointer",
            }}
          />
        </Link>
        <div
          className={navbarBackdrop({ isOpen })}
          onClick={() => setIsOpen(false)}
        />
        <ul className={desktopLinks()}>
          {NavList.map(
            (
              nav: { link: string; label: string; icon: React.ReactNode },
              index: number
            ) => {
              return (
                <li key={index}>
                  <NavLinkButton
                    label={nav.label}
                    link={nav.link}
                    icon={nav.icon}
                  />
                </li>
              );
            }
          )}
        </ul>
        {/* Sponsorship */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderWidth: 0,
            padding: "12px 16px",
            borderRadius: 8,
            height: 72,
            border: "1px solid #EEEEEE",
          }}
        >
          <div
            style={{
              marginRight: 14,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FAEBF7",
              padding: 15,
              borderRadius: 8,
            }}
          >
            <HeartIcon size={20} color="#DE89D2" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                margin: 0,
                color: "#000000",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 700,
                fontFamily: "Roslindale",
              }}
            >
              Parrainer mes amis
            </p>
            <p
              style={{
                margin: 0,
                color: "#000000",
                fontSize: 12,
                lineHeight: "16px",
              }}
            >
              et gagnez 25€ !
            </p>
            <b>{profile.raf_code}</b>
          </div>
        </div> */}
      </div>

      <div className={content()}>{children}</div>
    </div>
  );
}
