import { ReactNode } from "react";
import { useIntercom } from "src/use-intercom";

import { Button } from "src/components/button";
import { css, keyframes, theme } from "src/style/theme";
import { useIntercomLoaded } from "src/utils/intercom";

const fadeIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

export const buttonWrapper = css({
  display: "inline-block",
  width: "100%",

  "@bp1": {
    width: "unset",
  },
});

const submitButtonWrapper = css({
  padding: "16px 16px",
  background: theme.colors.white,

  boxShadow:
    "0px 0px 2px rgba(0, 0, 0, 0.08), 0px 10px 16px rgba(0, 0, 0, 0.16)",
  display: "flex",

  opacity: 0,
  animation: `${fadeIn} 0s 100ms linear forwards`,

  variants: {
    isValid: {
      true: {
        display: "flex",
      },
    },
  },

  "@bp1": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    border: "1px solid #F5F5F5",
  },
});

interface Props {
  children: ReactNode;
  totalPrice?: number;
  isValid: boolean;
  isLoading: boolean;
}

export function BackIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1669 9.16666H7.84191L10.5919 6.42499C10.7488 6.26807 10.837 6.05524 10.837 5.83333C10.837 5.61141 10.7488 5.39858 10.5919 5.24166C10.435 5.08474 10.2222 4.99658 10.0002 4.99658C9.77832 4.99658 9.56549 5.08474 9.40857 5.24166L5.24191 9.40833C5.16604 9.48758 5.10657 9.58103 5.06691 9.68333C4.98356 9.88621 4.98356 10.1138 5.06691 10.3167C5.10657 10.419 5.16604 10.5124 5.24191 10.5917L9.40857 14.7583C9.48604 14.8364 9.57821 14.8984 9.67976 14.9407C9.78131 14.983 9.89023 15.0048 10.0002 15.0048C10.1102 15.0048 10.2192 14.983 10.3207 14.9407C10.4223 14.8984 10.5144 14.8364 10.5919 14.7583C10.67 14.6809 10.732 14.5887 10.7743 14.4871C10.8166 14.3856 10.8384 14.2767 10.8384 14.1667C10.8384 14.0566 10.8166 13.9477 10.7743 13.8462C10.732 13.7446 10.67 13.6525 10.5919 13.575L7.84191 10.8333H14.1669C14.3879 10.8333 14.5999 10.7455 14.7562 10.5892C14.9124 10.433 15.0002 10.221 15.0002 9.99999C15.0002 9.77898 14.9124 9.56702 14.7562 9.41074C14.5999 9.25446 14.3879 9.16666 14.1669 9.16666Z"
        fill="#212121"
      />
    </svg>
  );
}

export function Intercom() {
  const intercom = useIntercom();
  const hasIntercom = useIntercomLoaded();

  if (!hasIntercom) {
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          bottom: "calc(100% + 20px)",
          background: theme.colors.electric as any as string,
          borderRadius: 9999,
          width: 60,
          height: 60,
          right: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fill: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          intercom.show();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 32"
          style={{ width: 28, height: 32 }}
        >
          <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
        </svg>
      </div>
    </div>
  );
}

function Footer({ children }: { children: ReactNode }) {
  return (
    <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
      <Intercom />
      {children}
    </div>
  );
}

export function SubmitButton({
  children,
  totalPrice,
  isValid,
  isLoading,
}: Props) {
  return (
    <Footer>
      <div className={submitButtonWrapper({ isValid })}>
        <div className={buttonWrapper()}>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!isValid}
            isBold
            fullWidth
            style={{
              // Make this button always larger than backbutton
              minWidth: 135,
            }}
          >
            {children}
          </Button>
        </div>
      </div>
    </Footer>
  );
}
