import { RestAPI } from "@aws-amplify/api-rest";
import { Auth } from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import { toast } from "react-toastify";
import { Claim, Invoice, Pet, Profile, StripeInvoice } from "src/types";

export async function fetchProfile(): Promise<Profile> {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.get("apiMySpaceGateway", "/contact", {
    headers: { Authorization: token },
    response: false,
  });

  return response.data;
}

export async function fetchContracts(): Promise<Pet[]> {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.get("apiMySpaceGateway", "/contracts", {
    headers: { Authorization: token },
    response: false,
  });

  return response.data;
}

export async function fetchInvoices(): Promise<Invoice[]> {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.get("apiMySpaceGateway", "/invoices", {
    headers: { Authorization: token },
    response: false,
  });

  return response;
}

export async function fetchInvoice(invoice_id: string): Promise<Invoice[]> {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.post("apiMySpaceGateway", "/invoices", {
    headers: { Authorization: token, "Content-Type": "application/json" },
    body: {
      invoice_id,
    },

    response: false,
  });

  return response;
}

export async function fetchInvoicePdf(
  invoice_id: string
): Promise<StripeInvoice> {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.get("apiMySpaceGateway", "/invoice", {
    headers: { Authorization: token },
    queryStringParameters: {
      invoice_id: invoice_id,
    },

    response: false,
  });

  return response;
}

export async function resetPassword(email: string) {
  return RestAPI.post("apiAuthGateway", "/reset-password", {
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
    body: {
      email: email,
    },
  })
    .then(() => {
      toast(`Un nouveau mot de passe vous a été envoyé à l'adresse ${email}`, {
        type: toast.TYPE.INFO,
        autoClose: 5000,
      });
    })
    .catch((err) => {
      // console.log(err);
      toast(
        "Une erreur est survenue. Merci de nous écrire à contact@dalma.co si le problème persiste.",
        { type: toast.TYPE.ERROR, autoClose: 10000 }
      );
    });
}

export async function fetchStatus(email: string) {
  return RestAPI.get("apiAuthGateway", "/status", {
    headers: {},
    queryStringParameters: {
      email: email,
    },
  });
}

export async function requestTelevet(type: string, phone: string) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return RestAPI.post("apiTelevetGateway", `/request/${type}`, {
    headers: { Authorization: token },
    body: {
      phone_number: phone,
    },
  });
}

function buildClaimId() {
  return "C_BLU_" + Math.random().toString(36).slice(2, 10);
}

export async function newClaim(
  contractUuid: string,
  { factures, fds }: { factures: File[]; fds: File[] }
) {
  const user = await Auth.currentAuthenticatedUser();
  const email = user.attributes.email.toLowerCase();

  const claimCreationDate = new Date().toISOString();
  const listKeys = await Storage.list(`${email}/`, {
    level: "protected",
  });
  const claimIds = listKeys.map((x) => x.key?.split("/")[1]);
  let claimId = buildClaimId();

  while (claimIds.includes(claimId)) {
    claimId = buildClaimId();
  }

  // Upload all documents to s3
  await Promise.all([
    ...factures.map((file) =>
      Storage.put(
        [email, claimId, "Facture", claimCreationDate, file["name"]].join("/"),
        file,
        {
          level: "protected",
          contentType: file.type,
        }
      )
    ),
    ...fds.map((file) =>
      Storage.put(
        [
          email,
          claimId,
          "Feuille de soin",
          claimCreationDate,
          file["name"],
        ].join("/"),
        file,
        {
          level: "protected",
          contentType: file.type,
        }
      )
    ),
  ]);

  const token = user.signInUserSession.idToken.jwtToken;
  // Open claim
  RestAPI.post("apiClaimsGateway", "/new", {
    headers: { Authorization: token },
    response: false,
    body: {
      contract_uuid: contractUuid,
      claim_id: claimId,
    },
  });
}

// const CLAIMS = ["PAID", "NEED_INFO", "IN_ANALYSIS", "noRefund"] as const;

export async function fetchClaims(): Promise<Claim[]> {
  // TODO_CLAIM add api that returns these fields
  // Currently only status and date are used
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const response = await RestAPI.get("apiMySpaceGateway", "/claims", {
    headers: { Authorization: token },
    response: false,
  });
  return response.data.all_claims;

  // return {
  //   all_claims: CLAIMS.map((status, index) => ({
  //     status,
  //     date: "2021-12-01",
  //     refundAmount: "2000",
  //     paidAmount: "2000",
  //     claim_id: "abcd_" + index,
  //     pet_name: "Artemis",
  //     contract_uuid: "CONTRACT_UUID",
  //   })),
  // }.all_claims;
}
