export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;

export const passwordRequirements = [
  { requirement: "au moins 8 caractères.", text: "8 caractères" },
  { requirement: "au moins 1 chiffre", text: "1 chiffre" },
  { requirement: "au moins un caractère special", text: "1 caractère spécial" },
  { requirement: "au moins 1 majuscule", text: "1 majuscule" },
  { requirement: "au moins 1 miniscule", text: "1 minuscule" },
];

export const NotValidPasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#E14A77"
    >
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
    </svg>
  );
};

export const ValidPasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#409059"
    >
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
    </svg>
  );
};

export const PasswordValidator = (password: string) => {
  const newPasswordError = [];

  if (password.length < 8) {
    newPasswordError.push("au moins 8 caractères.");
  }
  if (!/[0-9]/.test(password)) {
    newPasswordError.push("au moins 1 chiffre");
  }
  if (!/[a-z]/.test(password)) {
    newPasswordError.push("au moins 1 miniscule");
  }
  if (!/[A-Z]/.test(password)) {
    newPasswordError.push("au moins 1 majuscule");
  }
  if (
    !/[= + \- ^ $ * . [ \] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ `]/.test(
      password
    )
  ) {
    newPasswordError.push("au moins un caractère special");
  }
  console.log({ newPasswordError });
  return newPasswordError;
};
