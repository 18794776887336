import { styled, theme } from "src/style/theme";
import { CheckIcon, ExclamationIcon } from "src/components/icons";
import { formatDate } from "src/utils/date";

const Card = styled("div", {
  width: "100%",
  padding: 24,
  border: "1px solid #DBEBFF",
  borderRadius: 8,
});

const StepContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StepPadding = styled("div", {
  backgroundColor: "white",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 38,
  height: 38,
});

const Step = styled("div", {
  backgroundColor: "#E9F3FD",
  borderRadius: "100%",
  position: "relative",
  width: 32,
  height: 32,

  "> div": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "> div > p": {
    margin: 0,
    fontFamily: "Roslindale",
    fontWeight: 700,
    color: theme.colors.electric500,
    fontSize: 18,
    lineHeight: "28px",
  },

  variants: {
    currentState: {
      true: {
        backgroundColor: theme.colors.electric500,

        "> div > p": {
          color: theme.colors.white,
        },
      },
    },
  },
});

const StepLine = styled("div", {
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "4px",
  height: "100%",
  backgroundColor: theme.colors.blue100,
});

const TableCellContainer = styled("div", {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const TableCell = styled("td", {
  paddingLeft: 20,

  "> h1": {
    margin: 0,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "16px",
    color: theme.colors.blue600,
  },

  "> p": {
    margin: 0,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.colors.blue600,
    marginTop: 4,
  },

  variants: {
    currentState: {
      true: {
        "> h1": {
          color: theme.colors.electric500,
        },

        "> p": {
          color: theme.colors.electric800,
        },
      },
    },
  },
});

const MissingDocument = styled("div", {
  display: "flex",
  float: "left",
  backgroundColor: theme.colors.accentOrangeLight,
  borderRadius: 6,
  padding: "4px 6px",
  marginTop: 16,
  alignItems: "center",

  "> p": {
    margin: "0px 0px 0px 5px",
    color: theme.colors.accentOrange,
    fontSize: 12,
    lineHeight: "14px",
  },
});

export const ReimbursementStateCard = ({
  state,
  missingDocs,
  openDate,
}: {
  state: 1 | 2 | 3 | 4;
  missingDocs?: boolean;
  openDate: Date;
}) => {
  return (
    <Card>
      <table cellSpacing={0}>
        <tbody>
          <tr>
            <td rowSpan={3}>
              <StepContainer>
                <StepLine>test</StepLine>
                <StepPadding>
                  <Step currentState={Boolean(state === 1)}>
                    <div>
                      {state > 1 ? (
                        <CheckIcon color="#688AB8" size={14} />
                      ) : (
                        <p>1</p>
                      )}
                    </div>
                  </Step>
                </StepPadding>
                <StepPadding style={{ margin: "55px 0px" }}>
                  <Step currentState={Boolean(state === 2)}>
                    <div>
                      {state > 2 ? (
                        <CheckIcon color="#688AB8" size={14} />
                      ) : (
                        <p>2</p>
                      )}
                    </div>
                  </Step>
                </StepPadding>
                <StepPadding>
                  <Step currentState={Boolean(state === 3)}>
                    <div>
                      {state > 3 ? (
                        <CheckIcon color="#688AB8" size={14} />
                      ) : (
                        <p>3</p>
                      )}
                    </div>
                  </Step>
                </StepPadding>
              </StepContainer>
            </td>
            <td rowSpan={3} style={{ position: "relative", width: "100%" }}>
              <TableCellContainer>
                <TableCell currentState={Boolean(state === 1)}>
                  <h1>Vous envoyez vos justificatifs</h1>
                  <p>Demande faite le {formatDate(openDate, "d MMMM yyyy")}</p>
                </TableCell>
                <TableCell currentState={Boolean(state === 2)}>
                  <h1>Nous analysons votre dossier</h1>
                  <p>L'équipe Dalma regarde vos documents en détail.</p>
                  {missingDocs && (
                    <MissingDocument>
                      <ExclamationIcon color="#FE6F18" size={13} />
                      <p>Document manquant</p>
                    </MissingDocument>
                  )}
                </TableCell>
                <TableCell currentState={Boolean(state === 3)}>
                  <h1>Votre urgence est remboursée</h1>
                  <p>
                    En général, sous 1 jour ouvré après réception de vos
                    documents.
                  </p>
                </TableCell>
              </TableCellContainer>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};
