import { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";

import { Input } from "src/components/input";
import { Button } from "src/components/button";
import AuthForm from "src/components/authForm";

import {
  NotValidPasswordIcon,
  passwordRequirements,
  PasswordValidator,
  ValidPasswordIcon,
} from "src/utils/password";

type FormValues = {
  email: string;
  codePassword: string;
  newPassword: string;
};

type ValidateFormValues = {
  email: string;
  codePassword: string;
  newPassword: string[];
};

function validate(values: FormValues) {
  // console.log("validate :: ", values);
  const errors: Partial<ValidateFormValues> = {};

  if (!values.codePassword) {
    errors.codePassword = "Code de vérification obligatoire";
  }

  if (!values.newPassword) {
    errors.newPassword = ["Veuillez renseigner le nouveau mot de passe"];
  } else {
    const newPasswordError = PasswordValidator(values.newPassword);

    if (newPasswordError.length > 0) {
      errors.newPassword = newPasswordError;
    }
  }
  return errors;
}

export function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email") || location.state?.email || "";

  useEffect(() => {
    if (!email) {
      navigate("/forgot-password", { replace: true });
    }
  }, [email, navigate]);

  const forgotPasswordSubmit = async (values: FormValues) => {
    // console.log('forgotPasswordSubmit :: ', values)
    // Collect confirmation code and new password, then
    await Auth.forgotPasswordSubmit(
      values.email,
      values.codePassword,
      values.newPassword
    )
      .then(() => {
        // console.log("data :: ", data)
        Auth.signIn(values.email, values.newPassword).then(() => {
          navigate("/", { replace: true });
        });
      })
      .catch((err) => {
        // console.log("error :: ", err);
        if (err.code === "CodeMismatchException") {
          toast("Le code de vérification est incorrect.", {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });
        } else if (err.code === "ExpiredCodeException") {
          toast("Le code de vérification a déjà été utilisé.", {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });
        } else if (err.code === "LimitExceededException") {
          toast(
            "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co",
            { type: toast.TYPE.WARNING, autoClose: 5000 }
          );
        } else if (err.code === "InvalidParameterException") {
          toast(
            "Le mot de passe n'est pas sécurisé. Il doit contenir au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
            { type: toast.TYPE.WARNING, autoClose: 5000 }
          );
        } else {
          toast(
            "Une erreur est survenue. Verifiez que vous saisi le bon 'code de vérification' qui vous a été envoyé par mail et renseigné un mot de passe contenant au moins 8 lettres, majuscule et miniscule, caractères spéciaux",
            { type: toast.TYPE.ERROR, autoClose: 10000 }
          );
        }
      });
  };

  return (
    <AuthForm
      title="Changer votre mot de passe"
      subTitle="Veuillez saisir le code de vérification envoyé par mail, ainsi que le nouveau mot de passe"
      previousPage="/forgot-password"
    >
      <Formik
        validate={validate}
        validateOnMount
        initialValues={{
          email: email,
          codePassword: "",
          newPassword: "",
        }}
        onSubmit={forgotPasswordSubmit}
      >
        {({ values, errors, isSubmitting, isValid }) => (
          <Form>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Email"
                name="email"
                type="email"
              />
            </div>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Code de vérification (envoyé par mail)"
                name="codePassword"
              />
            </div>
            <div style={{ display: "flex", marginBottom: "21px" }}>
              <Input
                label="Nouveau mot de passe"
                name="newPassword"
                type="password"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#F5F5F5",
                padding: "10px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#616161", margin: "0px 0px 3px 0px" }}>
                {"Votre nouveau mot de passe doit contenir au moins :"}
              </p>
              {passwordRequirements.map((data, index) => {
                return (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    key={index}
                  >
                    {(errors.newPassword &&
                      errors.newPassword.includes(data.requirement)) ||
                    !values.newPassword ? (
                      <NotValidPasswordIcon />
                    ) : (
                      <ValidPasswordIcon />
                    )}
                    <p style={{ color: "#616161", margin: "0px 0px 0px 10px" }}>
                      {data.text}
                    </p>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex", marginBottom: "23px" }}>
              <Button
                style={{ marginTop: "4px", width: "100%" }}
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Envoyer
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthForm>
  );
}
