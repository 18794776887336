import { ComponentProps, ReactNode } from "react";
import { css, theme, styled } from "src/style/theme";
import { CheckIcon, ClockIcon, FailureIcon, RightArrowIcon } from "./icons";

const card = (hasOnClick: boolean, border: string, boxShadow: string) =>
  css({
    cursor: hasOnClick ? "pointer" : "unset",
    height: "100%",
    display: "flex",
    width: "100%",
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    boxShadow: boxShadow ? boxShadow : "unset",
    border: border ? border : "unset",
    borderRadius: 12,
    flexDirection: "column",
    justifyContent: "space-between",
  });

const StatusCard = styled("div", {
  padding: 8,
  borderRadius: 8,
  fontSize: 12,
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  variants: {
    pending: {
      true: {
        backgroundColor: theme.colors.blueBackGround,
        color: theme.colors.blue800,
      },
    },
    success: {
      true: {
        backgroundColor: theme.colors.greenBackGround,
        color: theme.colors.green,
      },
    },
    failure: {
      true: {
        backgroundColor: theme.colors.redBackGround,
        color: theme.colors.red,
      },
    },
  },
});

const TitleStyle = (titleColor: string) =>
  css({
    color: titleColor,
    fontSize: 14,
    lineHeight: "16px",
    margin: 0,

    "@bp1": {
      fontSize: 16,
      lineHeight: "20px",
    },
  });

const SubTitleStyle = (subtitleColor: string) =>
  css({
    fontSize: 12,
    lineHeight: "14px",
    margin: "4px 0px 0px 0px",
    color: subtitleColor,

    "@bp1": {
      fontSize: 14,
      lineHeight: "16px",
    },
  });

const IconContainerStyle = (color: string) =>
  css({
    backgroundColor: color,
    padding: 14,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    float: "none",

    "@bp1": {
      float: "left",
    },
  });

const Container = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  height: "100%",

  "> div": {
    display: "flex",
    alignItems: "center",
    padding: 16,
  },

  "@bp1": {
    "> div": {
      display: "block",
      padding: 24,
    },
  },
});

const TextContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginLeft: 16,

  "> div": {
    marginTop: 0,
  },

  "@bp1": {
    marginLeft: 0,

    "> div": {
      marginTop: 25,
    },
  },
});

export function Card({
  title,
  titleColor = "#000000",
  subtitle,
  subtitleColor = "#616161",
  icon,
  iconBgColor = "transparent",
  status,
  onClick,
  boxShadow = "0px 0px 2px rgba(20, 66, 131, 0.1), 0px 2px 10px rgba(20, 66, 131, 0.1)",
  border = "",
  ...props
}: {
  title: ReactNode;
  titleColor?: string;
  subtitle: ReactNode;
  subtitleColor?: string;
  icon?: ReactNode;
  iconBgColor?: string;
  status?: "pending" | "success" | "failure";
  onClick?: () => void;
  boxShadow?: string;
  border?: string;
} & ComponentProps<"div">) {
  return (
    <div
      onClick={onClick}
      className={card(onClick ? true : false, border, boxShadow)()}
      {...props}
    >
      <Container>
        <div>
          <div className={IconContainerStyle(iconBgColor)()}>{icon}</div>
          <TextContainer>
            <div>
              <h1 className={TitleStyle(titleColor)()}>{title}</h1>
              <p className={SubTitleStyle(subtitleColor)()}>{subtitle}</p>
            </div>
          </TextContainer>
        </div>
        {onClick && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <RightArrowIcon color="#757575" size={14} />
          </div>
        )}
      </Container>
      {status && (
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.08)",
            borderLeftWidth: 0,
            borderBottomWidth: 0,
            borderRightWidth: 0,
            padding: "12px 16px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {status === "pending" && (
            <StatusCard pending>
              <ClockIcon color="#144283" size={15} />
              <p style={{ margin: "0px 0px 0px 7px" }}>En cours d’analyse</p>
            </StatusCard>
          )}
          {status === "success" && (
            <StatusCard success>
              <CheckIcon color="#00704F" size={15} />
              <p style={{ margin: "0px 0px 0px 7px" }}>Traité</p>
            </StatusCard>
          )}
          {status === "failure" && (
            <StatusCard failure>
              <FailureIcon color="#e6787a" size={15} />
              <p style={{ margin: "0px 0px 0px 7px" }}>Échec</p>
            </StatusCard>
          )}
        </div>
      )}
    </div>
  );
}
