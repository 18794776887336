import { useState } from "react";
import { CoverageModal } from "src/pages/urgence";
import { css, theme } from "src/style/theme";
import { Claim } from "src/types";
import { formatDate } from "src/utils/date";
import { button } from "./button";
import {
  CheckIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  FileQuestionIcon,
  RefundIcon,
  RightArrowIcon,
} from "./icons";
import { Modal } from "./modal";
import { formatPrice } from "./profile";
import { ReimbursementStateCard } from "./reimbursement-state-card";
import { ReimbursementCard } from "./reimbursementCard";
import { Stack } from "./stack";
import { TextBox } from "./textBox";

export function Reimbursement({
  claim,
  email,
}: {
  claim: Claim;
  email: string;
}) {
  const [modal, showModal] = useState(false);
  const [coverTable, openCoverTable] = useState(false);
  const openDate = new Date(claim.date);

  return (
    <>
      {modal ? (
        <Modal
          title={getModalTitle(claim.status)}
          formattedTitle={getFormattedTitle(claim.status)}
          onDismiss={() => showModal(false)}
        >
          {claim.status === "IN_ANALYSIS" ? (
            <ReimbursementStateCard state={2} openDate={openDate} />
          ) : claim.status === "NEED_INFO" ? (
            <>
              <TextBox type="warning">
                <p style={{ marginTop: 0 }}>
                  Nous avons besoin d’un document supplémentaire afin de
                  débloquer votre remboursement.
                </p>
                <p>
                  Des précisions vous ont été envoyées par mail à <b>{email}</b>{" "}
                  📩
                </p>
              </TextBox>
              <div style={{ height: 20 }} />
              <ReimbursementStateCard
                state={2}
                openDate={openDate}
                missingDocs
              />
            </>
          ) : claim.status === "PAID" ? (
            <>
              <ReimbursementCard
                placement={"none"}
                price={claim.paidAmount}
                reimbursementPercentage={
                  claim.paidAmount
                    ? (100 * claim.refundAmount) / claim.paidAmount
                    : 100
                }
              >
                {claim.title}
              </ReimbursementCard>
              <div style={{ margin: "24px 0px" }}>
                <ReimbursementCard
                  placement="top"
                  hideTopBorderBottomWidth
                  leftRender={
                    <h1
                      style={{
                        margin: 0,
                        fontSize: 14,
                        lineHeight: "16px",
                        fontWeight: 400,
                      }}
                    >
                      Total dépensé
                    </h1>
                  }
                  rightRender={
                    <p
                      style={{
                        margin: 0,
                        fontSize: 14,
                        lineHeight: "20px",
                        color: "#585B66",
                      }}
                    >
                      {`${formatPrice(claim.paidAmount * 100)} €`}
                    </p>
                  }
                />
                <ReimbursementCard
                  placement="bottom"
                  leftRender={
                    <h1
                      style={{
                        margin: 0,
                        fontSize: 14,
                        lineHeight: "16px",
                        fontWeight: 700,
                      }}
                    >
                      Total remboursé par Dalma
                    </h1>
                  }
                  rightRender={
                    <p
                      style={{
                        margin: 0,
                        fontSize: 18,
                        lineHeight: "20px",
                        color: "#00704F",
                        fontWeight: 700,
                      }}
                    >
                      {`${formatPrice(claim.refundAmount * 100)} €`}
                    </p>
                  }
                />
              </div>
              <div
                style={{
                  marginTop: 44,
                  paddingTop: 24,
                  /* Gris/300 */
                  borderTop: "1px solid #DEDFE6",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              />
              <ReimbursementStateCard state={3} openDate={openDate} />
            </>
          ) : (
            <>
              {coverTable ? (
                <CoverageModal onClose={() => openCoverTable(false)} />
              ) : null}
              <TextBox type="danger">
                <p style={{ marginTop: 0 }}>
                  Nous n'avons pas pu rembourser vos frais. Consultez la{" "}
                  <button
                    style={{
                      color: "#D6444C",
                      fontSize: "inherit",
                      textDecoration: "none",
                      background: "none",
                      border: "none",
                      padding: 0,
                      display: "inline",
                      appearance: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => openCoverTable(true)}
                  >
                    liste des soins couverts
                  </button>{" "}
                  et les modalités de l'offre dalma.blue pour en savoir plus.
                </p>
                <p>
                  Votre fonds d’urgence n’a pas été débloqué et reste disponible
                  pour une prochaine utilisation 🙌
                </p>
              </TextBox>
            </>
          )}

          <div
            style={{
              marginTop: 48,
              paddingTop: 24,
              /* Gris/300 */
              borderTop: "1px solid #DEDFE6",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginBottom: 16,
                fontSize: 14,
                lineHeight: "16px",
                textAlign: "center",

                /* Grey/800 */
                color: "#424242",
              }}
            >
              Une question
            </div>

            <a
              href="mailto:contact@dalma.co"
              className={button({ variant: "blue", fullWidth: true })}
            >
              Nous contacter
            </a>
          </div>
        </Modal>
      ) : null}
      <div
        style={{
          background: "white",

          /* Blue/100 */
          border: "1px solid #E9F3FD",

          /* Elevation / 01 */
          boxShadow:
            "0px 0px 2px rgba(20, 66, 131, 0.1), 0px 2px 10px rgba(20, 66, 131, 0.1)",
          borderRadius: 8,
        }}
      >
        <div
          style={{ padding: 20, cursor: "pointer" }}
          tabIndex={-1}
          onClick={() => showModal(true)}
        >
          <Stack direction="row" center css={{ $$margin: "16px" }}>
            <div
              style={{
                padding: 14,
                background: "#FAEEE6",
                borderRadius: 8,
                alignSelf: "center",
                display: "flex",
              }}
            >
              <RefundIcon color="#FE6F18" size={20} />
            </div>
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  lineHeight: "16px",

                  /* Electrique/800 */
                  color: "#1F2A62",
                }}
              >
                Remboursement
              </div>
              <div
                style={{
                  marginTop: 4,
                  fontSize: 12,
                  lineHeight: "16px",

                  /* Blue/600 */
                  color: "#688AB8",
                }}
              >
                {/* TODO_CLAIM Hide paidAmount as we do not have it until the claim receipt has been manually parsed */}
                {false && <>{claim.paidAmount}€ • </>}
                {formatDate(openDate, "d MMM yyyy")}
              </div>
            </div>
            <div style={{ flex: 1, margin: 0 }} />
            <div>
              <RightArrowIcon size={24} color="#688AB8" />
            </div>
          </Stack>
        </div>
        <div
          style={{
            display: "flex",
            /* Blue/100 */
            borderTop: "1px solid #E9F3FD",
            padding: "12px 16px",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Status status={claim.status} />
        </div>
      </div>
    </>
  );
}

const refundStatus = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: 8,

  borderRadius: 8,
  variants: {
    status: {
      PAID: {
        color: "#00704F",
        backgroundColor: "#DDF0EA",
      },
      IN_ANALYSIS: {
        color: theme.colors.electric800,
        backgroundColor: "#F1F7FF",
      },
      NEED_INFO: {
        color: "#FE6F18",
        backgroundColor: "#FAEEE6",
      },
      noRefund: {
        color: "#D6444C",
        background: "rgba(250, 230, 231, 0.5)",
      },
    },
  },
});

function Status({ status }: Pick<Claim, "status">) {
  const text = {
    PAID: "Traité",
    IN_ANALYSIS: "En cours d\u2019analyse",
    NEED_INFO: "Documents manquants",
    noRefund: "Refusé",
  }[status];

  return (
    <div className={refundStatus({ status })}>
      <Stack direction="row" css={{ $$margin: "6px" }} center>
        {status === "PAID" ? (
          <CheckIcon size={16} color="currentColor" />
        ) : status === "IN_ANALYSIS" ? (
          <ClockIcon color="currentColor" size={16} />
        ) : status === "NEED_INFO" ? (
          <FileQuestionIcon size={16} />
        ) : (
          <ExclamationTriangleIcon color="currentColor" size={16} />
        )}
        <div>{text}</div>
      </Stack>
    </div>
  );
}

// TODO non blocking
// We need a string for a11y, and a dom node so we can highlight the text
// Ideally, we should generate both from a common source, but duplication
// Works for now and is simpler
const MODAL_TEXT = {
  PAID: {
    string: "Votre remboursement à été traité",
    formatted: (
      <>
        Votre remboursement à été <em>traité</em>
      </>
    ),
  },
  IN_ANALYSIS: {
    string: "Votre remboursement est en cours d\u2019analyse",
    formatted: (
      <>
        Votre remboursement est <em>en cours d{"\u2019"}analyse</em>
      </>
    ),
  },
  NEED_INFO: {
    string: "Votre remboursement est en attente",
    formatted: (
      <>
        Votre remboursement est <em>en attente</em>
      </>
    ),
  },
  noRefund: {
    string: "Votre remboursement à été refusé",
    formatted: (
      <>
        Votre remboursement à été <em>refusé</em>
      </>
    ),
  },
};

function getModalTitle(status: Claim["status"]) {
  return MODAL_TEXT[status].string;
}

const formattedTitle = css({
  "& > em": {
    fontStyle: "normal",
  },
  variants: {
    status: {
      PAID: {
        "& > em": {
          color: "#00704F",
        },
      },
      IN_ANALYSIS: {
        "& > em": {
          color: theme.colors.electric,
        },
      },
      NEED_INFO: {
        "& > em": {
          color: "#FE6F18",
        },
      },
      noRefund: {
        "& > em": {
          color: "#D6444C",
        },
      },
    },
  },
});

function getFormattedTitle(status: Claim["status"]) {
  return (
    <span className={formattedTitle({ status })}>
      {MODAL_TEXT[status].formatted}
    </span>
  );
}
