import { Auth } from "@aws-amplify/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import { Input } from "src/components/input";
import { Button } from "src/components/button";
import AuthForm from "src/components/authForm";

import { EMAIL_REGEX } from "src/utils/password";
import { fetchStatus, resetPassword } from "src/utils/backend";

type FormValues = {
  email: string;
};

const validate = (values: FormValues) => {
  const errors: Partial<FormValues> = {};

  if (!values.email) {
    errors.email = "Obligatoire";
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = "Email invalide";
  }

  return errors;
};

export function ForgotPassword({ nextPage = "/change-password" }) {
  const navigate = useNavigate();

  const forgotPassword = async (values: FormValues) => {
    const res = await fetchStatus(values.email);
    if (res === "FORCE_CHANGE_PASSWORD") {
      await resetPassword(values.email);
      navigate("/login", { state: { email: values.email }, replace: true });
    } else if (res === "CONFIRMED") {
      await Auth.forgotPassword(values.email)
        .then(async () => {
          navigate(nextPage, { state: { email: values.email }, replace: true });
        })
        .catch((err) => {
          // console.log("error :: ", err);
          if (err.code === "LimitExceededException") {
            toast(
              "Vous avez atteint le plafond de tentative de reinitialisation du mot de passe. Veuillez patientez 1h ou écrire à contact@dalma.co",
              { type: toast.TYPE.WARNING, autoClose: 5000 }
            );
          } else {
            toast("Une erreur est survenue.", {
              type: toast.TYPE.ERROR,
              autoClose: 10000,
            });
          }
        });
    } else if (res.errorCode === 500) {
      toast(
        "Oups, votre email est introuvable ! Veuillez le vérifier et ré-essayer.",
        { type: toast.TYPE.WARNING, autoClose: 5000 }
      );
    } else {
      toast(
        "Une erreur est survenue. Veuillez nous écrire à contact@dalma.co",
        { type: toast.TYPE.ERROR, autoClose: 10000 }
      );
    }
  };

  return (
    <AuthForm
      title="Un petit oubli ?"
      subTitle="Entrez votre mail, nous allons vous envoyer un nouveau mot de passe"
      previousPage="/login"
    >
      <Formik
        initialValues={{
          email: "",
        }}
        validate={validate}
        validateOnMount
        onSubmit={forgotPassword}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div style={{ display: "flex", marginBottom: "16px" }}>
              <Input label="Adresse email" name="email" type="email" />
            </div>
            <div style={{ display: "flex", marginBottom: "23px" }}>
              <Button
                style={{ width: "100%", height: 50 }}
                type="submit"
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Envoyer
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthForm>
  );
}
