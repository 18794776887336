import { ReactNode } from "react";
import { theme, styled } from "src/style/theme";

const borderRadius = "8px";

const IconWrapper = styled("div", {
  width: "20px",
  height: "20px",
});

const Span = styled("span", {
  color: "" + theme.colors.gray800,
  fontStyle: "normal",
  fontWeight: "bold",
  textAlign: "start",
  fontSize: 14,
  width: "96px",
  marginLeft: "18px",

  "@bp1": {
    fontSize: 14,
  },

  variants: {
    selected: {
      true: {
        color: "" + theme.colors.electric,
      },
    },
  },
});

const ChatTypeWrapper = styled("div", {
  padding: 16,
  display: "flex",
  flexDirection: "row",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  border: "1px solid " + theme.colors.inputGreyBorder,
  background: "#FFFFFF",
  borderRadius: borderRadius,
  boxSizing: "border-box",
  fontSize: 12,
  margin: 4,
  cursor: "pointer",

  "@bp1": {
    width: "186px",
  },

  variants: {
    selected: {
      true: {
        border: "1px solid " + theme.colors.electric,
      },
    },
  },
});

export function ChatTypeCard({
  children,
  onClick,
  selected = false,
  icon,
}: {
  children: ReactNode;
  onClick: () => void;
  selected?: boolean;
  icon?: ReactNode;
}) {
  return (
    <ChatTypeWrapper onClick={onClick} selected={selected}>
      <IconWrapper>{icon}</IconWrapper>
      <Span selected={selected}>{children}</Span>
    </ChatTypeWrapper>
  );
}
