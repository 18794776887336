import React, { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useNavigate } from "react-router-dom";

import { css } from "src/style/theme";
import { LeftArrow } from "./icons";
import { Intercom } from "./modal-submit-button";

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle: string;
  previousPage: string;
  webLink?: boolean;
};

const backButton = css({
  flex: "none",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#DBEBFF",
  borderRadius: 100,
  padding: 12,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#e2e8f0",
  },
});

const AuthForm = ({
  children,
  title,
  subTitle,
  previousPage,
  webLink = false,
}: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isUserAlreadyAuthenticated = async () => {
      try {
        await Auth.currentSession();
        // alert('User is already authenticated');
        navigate("/", { replace: true });
      } catch (e) {
        console.log(e);
      }
    };

    isUserAlreadyAuthenticated();
  }, [navigate]);

  return (
    <>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          paddingBottom: 100,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fafafa",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 10,
            width: 500,
            maxWidth: "90vw",
          }}
        >
          <div
            style={{
              display: "flex",
              marginBottom: 25,
              alignItems: "center",
            }}
          >
            {webLink ? (
              <a href={previousPage} className={backButton()}>
                <LeftArrow color="#000000" />
              </a>
            ) : (
              <Link className={backButton()} to={previousPage} replace>
                <LeftArrow color="#000000" />
              </Link>
            )}
            <p
              style={{
                marginLeft: 10,
                fontFamily: "Roslindale",
                fontSize: "1.5em",
              }}
            >
              {title}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              borderWidth: "1px",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginBottom: "26px",
                maxWidth: "440px",
              }}
            >
              <p style={{ fontSize: "16px", fontWeight: 500 }}>{subTitle}</p>
            </div>

            {children}
          </div>
        </div>
      </div>
      <div style={{ position: "fixed", right: 0, bottom: 0 }}>
        <Intercom />
      </div>
    </>
  );
};

export default AuthForm;
