import React from "react";
import { toast } from "react-toastify";
import { styled, theme } from "src/style/theme";
import {
  CircleCheckIcon,
  TrashIcon,
  FileUploadIcon,
} from "src/components/icons";

const Upload = styled("label", {
  width: "100%",
  minHeight: 48,
  borderRadius: 12,
  border: "1px solid #CED0DA",
  backgroundColor: "#FFFFFF",
  outline: "none",
  display: "block",

  variants: {
    file: {
      true: {
        border: `2px solid ${theme.colors.electric}`,
        backgroundColor: "#EDF0FE",
      },
    },
  },
});

export const UploadFile = ({
  id,
  maxSize = 50000000, // 50 mb
  labelErrorSize = "Fichier(s) trop volumineux",
  label = "Ajouter un document",
  onAdd,
  file,
  onRemove,
}: {
  id?: string;
  label?: string;
  labelErrorSize?: string;
  maxSize?: number;
  formats?: string[];
  onAdd?: any;
  onRemove?: any;
  file?: File;
  index?: number;
}) => {
  const onFilesChange = (event: any) => {
    const fileTmp: File | undefined = event.target.files[0];
    if (fileTmp) {
      if (maxSize && fileTmp.size > maxSize) {
        return toast(labelErrorSize, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        onAdd(fileTmp);
        event.target.value = "";
      }
    }
  };

  return (
    <Upload file={file ? true : false}>
      <input
        style={{ display: "none" }}
        id={id ? id : "UploadFile"}
        type="file"
        onChange={onFilesChange}
        disabled={Boolean(file)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          borderRadius: 5,
        }}
      >
        <span
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            outline: "none",
            height: "100%",
            width: "100%",
            cursor: file ? "unset" : "pointer",
            display: "flex",
            alignItems: "center",
            padding: 14,
          }}
        >
          {file && <CircleCheckIcon color="#2F3E94" />}
          {file ? (
            <p
              style={{
                margin: "0px 0px 0px 10px",
                fontSize: 14,
                lineHeight: "22px",
                color: "#2F3E94",
              }}
            >
              {file.name} ({Math.round(file.size / 1000)} ko)
            </p>
          ) : (
            <p style={{ margin: 0, fontSize: 14, lineHeight: "22px" }}>
              {label}
            </p>
          )}
        </span>
        {file ? (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: 14,
            }}
            onClick={onRemove}
          >
            <TrashIcon color="#4E68F6" />
          </div>
        ) : (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: 14,
            }}
          >
            <FileUploadIcon color="#4E68F6" />
          </div>
        )}
      </div>
    </Upload>
  );
};
