import { format as dateFnsFormat } from "date-fns";
import { fr } from "date-fns/locale";

/**
 * Format dates with french locale
 *
 * Utility method to simplify calling `format` from `date-fns` with french locale
 */
export function formatDate(date: number | Date, format: string) {
  return dateFnsFormat(date, format, { locale: fr });
}
