import { ToastContainer } from "react-toastify";
import { styled } from "src/style/theme";

import "react-toastify/dist/ReactToastify.css";

const CustomToastContainer = styled(ToastContainer, {
  ".Toastify__toast--success": {
    background: "#d6f8db !important",
    fontSize: "14px",
    lineHeight: "20px",
    border: "1px solid #aff2b6",
  },
  ".Toastify__progress-bar": {
    height: "3px !important",
  },
});

export const DalmaToastify = () => {
  return (
    <CustomToastContainer
      position="top-center"
      autoClose={3000}
      pauseOnFocusLoss
      pauseOnHover
    />
  );
}
