import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { setUser } from "@sentry/minimal";
import { useEffect, useLayoutEffect, useState } from "react";
import { useIntercom } from "src/use-intercom";

export type AuthState = null | "signIn" | "loading";

export function useAuth() {
  const [auth, setAuth] = useState<AuthState>("loading");
  const { shutdown, boot } = useIntercom();

  const [initialIntercomConfig] = useState(window.intercomSettings);

  useLayoutEffect(() => {
    Auth.currentSession().then(
      () => setAuth("signIn"),
      () => setAuth(null)
    );
  }, []);

  useEffect(() => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn": {
          setAuth("signIn");
          break;
        }
        case "signOut": {
          setAuth(null);
          break;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (auth === "signIn") {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((user) => {
        shutdown();
        setUser({ email: user["attributes"]["email"] });
        boot({
          email: user["attributes"]["email"],
          ...initialIntercomConfig,
        });
      });
    }
    if (auth === null) {
      shutdown();
      setUser(null);
      boot(initialIntercomConfig);
    }
  }, [auth, shutdown, boot, initialIntercomConfig]);

  return auth;
}

export function signOut() {
  return Auth.signOut();
}
