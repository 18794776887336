import React from "react";
import { styled } from "src/style/theme";
import { InfoCircleIcon, ExclamationTriangleIcon } from "./icons";

const Container = styled("div", {
  display: "flex",
  backgroundColor: "rgba(78, 104, 246, 0.1)",
  border: "1px solid rgba(78, 104, 246, 0.5)",
  borderRadius: 8,
  padding: 16,

  variants: {
    warning: {
      true: {
        backgroundColor: "rgba(254, 111, 24, 0.1)",
        border: "1px solid rgba(254, 111, 24, 0.5)",
      },
    },
    danger: {
      true: {
        backgroundColor: "rgba(214, 68, 76, 0.1)",
        border: "1px solid rgba(214, 68, 76, 0.5)",
      },
    },
    disableBgColor: {
      true: {
        backgroundColor: "transparent",
      },
    },
  },
});

export const TextBox = ({
  children,
  type = "info",
  disabledIcon = false,
  disableBgColor = false,
}: {
  children: React.ReactNode;
  type?: "info" | "warning" | "danger";
  disabledIcon?: boolean;
  disableBgColor?: boolean;
}) => {
  return (
    <Container
      warning={Boolean(type === "warning")}
      danger={Boolean(type === "danger")}
      disableBgColor={disableBgColor}
    >
      <div>
        {!disabledIcon && type === "info" && <InfoCircleIcon color="#4E68F6" />}
        {!disabledIcon && type === "warning" && (
          <InfoCircleIcon color="#FE6F18" />
        )}
        {!disabledIcon && type === "danger" && (
          <ExclamationTriangleIcon color="#D6444C" />
        )}
      </div>
      <div style={{ paddingLeft: disabledIcon ? 0 : 9 }}>{children}</div>
    </Container>
  );
};
