import { styled } from "@stitches/react";

export const Stack = styled("div", {
  display: "flex",
  variants: {
    center: {
      true: {
        alignItems: "center",
      },
    },
    direction: {
      row: {
        flexDirection: "row",
        "& > * + *": {
          marginLeft: "$$margin",
        },
      },
      column: {
        flexDirection: "column",
        "& > * + *": {
          marginTop: "$$margin",
        },
      },
    },
    margin: {
      small: {
        $$margin: "25px",
      },
    },
  },
  defaultVariants: {
    direction: "column",
    margin: "small",
  },
});
