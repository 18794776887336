import { VariantProps } from "@stitches/react";
import { ReactNode, ComponentProps } from "react";
import { theme, keyframes, css, styled } from "src/style/theme";

export const rotate = keyframes({
  from: { transform: "translate3d(-50%, -50%, 0) rotate(0)" },
  to: { transform: "translate3d(-50%, -50%, 0) rotate(360deg)" },
});

export const button = css({
  appearance: "none",
  background: "transparent",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "14px 32px",
  cursor: "pointer",
  textDecoration: "none",

  /* Accent/Orange */

  color: "white",
  fontFamily: "inherit",

  /* Elevation 01 */
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",

  variants: {
    smallPadding: {
      true: {
        paddingRight: 25,
        paddingLeft: 25,
      },
    },
    variant: {
      primary: {
        $$color: theme.colors.electric,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.white,
        fontSmooth: "never",
      },
      blue: {
        $$color: theme.colors.blue200,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.black,
        fontSmooth: "never",
        boxShadow: "none",
      },
      darkBlue: {
        $$color: theme.colors.electric,
        background: "$$color",
        border: "1px solid $$color",
        color: theme.colors.white,
        fontSmooth: "never",
        boxShadow: "none",
      },
      outlined: {
        background: "transparent",
        color: "#000000",
        border: "1px solid #E0E0E0",
        boxShadow: "none",
      },
    },
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    isBold: {
      true: {
        fontWeight: 700,
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
        $$color: "#EEEEEE",
        color: "#9E9E9E",
      },
    },

    isLoading: {
      true: {
        "&::after": {
          fontSize: 8,
          boxSizing: "border-box",
          content: "",
          $$size: "1em",
          display: "inline-block",
          width: "calc(2 * $$size)",
          height: "calc(2 * $$size)",
          borderRadius: "$$size",
          border: "2px solid currentColor",
          borderTopColor: "transparent",
          animation: `${rotate} 1s linear infinite`,
          transform: `translate3d(-50%, -50%, 0)`,
          willChange: "transform",
          transformOrigin: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
        },
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },

  compoundVariants: [
    {
      disabled: true,
      variant: "primary",
      css: {
        cursor: "not-allowed",
        $$color: "#EEEEEE",
        color: "#9E9E9E",
      },
    },
  ],
});

const ButtonText = styled("span", {
  fontSize: 14,
  lineHeight: "22px",
  variants: {
    isLoading: {
      true: {
        color: "transparent",
      },
    },
  },
});

type ButtonProps = { children: ReactNode } & ComponentProps<"button"> &
  VariantProps<typeof button>;

export function Button({
  children,
  variant,
  fullWidth,
  isBold,
  isLoading,
  disabled,
  ...props
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      {...props}
      className={button({ variant, isLoading, fullWidth, disabled, isBold })}
    >
      <ButtonText isLoading={isLoading}>{children}</ButtonText>
    </button>
  );
}

export const buttonIcon = css({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  lineHeight: "22px",
});

export const iconClass = css({
  marginRight: 10,
  variants: {
    header: {
      true: {
        "@media (max-width: 374px)": {
          display: "none",
        },
      },
    },
  },
  "& > svg": {
    display: "block",
  },
});

export function ButtonIcon({
  icon,
  children,
  header = false,
}: {
  icon: ReactNode;
  children: ReactNode;
  header?: boolean;
}) {
  return (
    <div className={buttonIcon()}>
      <div className={iconClass({ header })}>{icon}</div>
      <div>{children}</div>
    </div>
  );
}
