import { useField } from "formik";
import { ComponentProps, ReactNode } from "react";
import { styled } from "@stitches/react";
import { theme } from "src/style/theme";
import dogHead from "./dog_head.svg";
import catHead from "./cat_head.svg";
import { Pet } from "src/types";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

type _InputType = {
  children: ReactNode;
  // Name and value is required
  petName: string;
  name: string;
  value: string;
  type: "dog" | "cat";
  used: boolean;
  pet: Pet;
} & Omit<ComponentProps<"input">, "type" | "value">;

const Span = styled("span", {
  display: "flex",
  direction: "row",
  position: "absolute",
  right: "16px",
  top: "40%",

  variants: {
    checked: {
      true: {
        color: theme.colors.electric,
      },
      false: {},
    },
  },
});

const Dot = styled("span", {
  position: "relative",
  top: 2,
  right: 2,
  width: 18,
  height: 18,
  border: `1px solid ${theme.colors.gray300}`,
  display: "inline-block",
  borderRadius: "50%",
  variants: {
    used: {
      true: { backgroundColor: theme.colors.gray100 },
    },
  },
});

const MainText = styled("div", {
  position: "relative",
  height: "34px",
  fontFamily: "Roslindale",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "20px",
  lineHeight: "28px",
  color: theme.colors.textColor,
  top: "20px",
  left: "105px",

  variants: {
    checked: {
      true: { color: theme.colors.electric },
      false: {},
    },
  },
});

const Text = styled("div", {
  position: "relative",
  height: "16px",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  color: theme.colors.gray600,
  lineHeight: "16px",
  top: "15px",
  left: "105px",
});

const Img = styled("img", {
  position: "absolute",
  width: "98px",
  height: "98px",
  left: "-5px",
  top: "-5px",
});

const Label = styled("label", {
  position: "relative",
  height: "88px",
  background: theme.colors.white,
  display: "block",
  boxShadow: "0 0 0 1px " + theme.colors.inputBlueBorder,
  overflow: "hidden",
  borderRadius: "16px",
  flex: "1 0 0",
  order: 0,
  cursor: "pointer",
  flexGrow: 1,

  variants: {
    alert: {
      true: {
        backgroundColor: "rgba(250, 230, 231, 0.5)",
        height: "unset",
        top: "-10px",
        padding: 16,
        paddingTop: 26,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        margin: "auto",
        width: "95%",
        boxShadow: "0 0 0 1px " + theme.colors.orangeBackGround,
        zIndex: -1,
      },
    },
    disable: {
      true: {
        "& > *": {
          opacity: 0.33,
        },
        cursor: "not-allowed",
      },
    },
    checked: {
      true: {
        boxShadow: "0 0 0 3px " + theme.colors.electric,
        background: theme.colors.blueBackGround,
      },
    },
  },
});

export function InputPet({
  children,
  petName,
  name,
  value,
  type,
  used,
  pet,
  onChange,
}: _InputType) {
  const [field] = useField({ name, type: "radio", value });
  let today: any = new Date();
  let startDate: any = new Date(pet.contract.start_date);
  startDate.setDate(startDate.getDate() + 15);
  let contractIsActive = today > startDate;

  return (
    <div>
      <Label checked={field.checked} disable={used || !contractIsActive}>
        <Span checked={field.checked}>
          {field.checked ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.22 8.79L10.93 13.09L9.28 11.44C9.19036 11.3353 9.08004 11.2503 8.95597 11.1903C8.83191 11.1303 8.69678 11.0965 8.55906 11.0912C8.42134 11.0859 8.28401 11.1091 8.15568 11.1594C8.02736 11.2096 7.91081 11.2859 7.81335 11.3833C7.7159 11.4808 7.63964 11.5974 7.58937 11.7257C7.53909 11.854 7.51589 11.9913 7.52121 12.1291C7.52653 12.2668 7.56026 12.4019 7.62028 12.526C7.6803 12.65 7.76532 12.7604 7.87 12.85L10.22 15.21C10.3134 15.3027 10.4243 15.376 10.5461 15.4258C10.6679 15.4755 10.7984 15.5008 10.93 15.5C11.1923 15.4989 11.4437 15.3947 11.63 15.21L16.63 10.21C16.7237 10.117 16.7981 10.0064 16.8489 9.88458C16.8997 9.76272 16.9258 9.63201 16.9258 9.5C16.9258 9.36799 16.8997 9.23728 16.8489 9.11542C16.7981 8.99356 16.7237 8.88296 16.63 8.79C16.4426 8.60375 16.1892 8.49921 15.925 8.49921C15.6608 8.49921 15.4074 8.60375 15.22 8.79ZM12.5 2C10.5222 2 8.58879 2.58649 6.9443 3.6853C5.29981 4.78412 4.01809 6.3459 3.26121 8.17317C2.50433 10.0004 2.3063 12.0111 2.69215 13.9509C3.078 15.8907 4.03041 17.6725 5.42894 19.0711C6.82746 20.4696 8.60929 21.422 10.5491 21.8079C12.4889 22.1937 14.4996 21.9957 16.3268 21.2388C18.1541 20.4819 19.7159 19.2002 20.8147 17.5557C21.9135 15.9112 22.5 13.9778 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2363 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2ZM12.5 20C10.9178 20 9.37104 19.5308 8.05544 18.6518C6.73985 17.7727 5.71447 16.5233 5.10897 15.0615C4.50347 13.5997 4.34504 11.9911 4.65372 10.4393C4.9624 8.88743 5.72433 7.46197 6.84315 6.34315C7.96197 5.22433 9.38743 4.4624 10.9393 4.15372C12.4911 3.84504 14.0997 4.00346 15.5615 4.60896C17.0233 5.21447 18.2727 6.23984 19.1518 7.55544C20.0308 8.87103 20.5 10.4177 20.5 12C20.5 14.1217 19.6572 16.1566 18.1569 17.6569C16.6566 19.1571 14.6217 20 12.5 20Z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <Dot used={used} />
          )}
        </Span>
        <div style={{ position: "relative" }}>
          <Img src={type === "cat" ? catHead : dogHead} />
          {used && (
            <div
              style={{ position: "absolute", bottom: "-30px", left: "50px" }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" rx="12" fill="#FAE6E7" />
                <path
                  d="M12 12.6663C11.8232 12.6663 11.6536 12.7366 11.5286 12.8616C11.4036 12.9866 11.3333 13.1562 11.3333 13.333V15.333C11.3333 15.5098 11.4036 15.6794 11.5286 15.8044C11.6536 15.9294 11.8232 15.9997 12 15.9997C12.1768 15.9997 12.3464 15.9294 12.4714 15.8044C12.5964 15.6794 12.6667 15.5098 12.6667 15.333V13.333C12.6667 13.1562 12.5964 12.9866 12.4714 12.8616C12.3464 12.7366 12.1768 12.6663 12 12.6663ZM15.3333 9.99967V8.66634C15.3333 7.78229 14.9821 6.93444 14.357 6.30932C13.7319 5.6842 12.884 5.33301 12 5.33301C11.1159 5.33301 10.2681 5.6842 9.64297 6.30932C9.01785 6.93444 8.66666 7.78229 8.66666 8.66634V9.99967C8.13622 9.99967 7.62752 10.2104 7.25244 10.5855C6.87737 10.9605 6.66666 11.4692 6.66666 11.9997V16.6663C6.66666 17.1968 6.87737 17.7055 7.25244 18.0806C7.62752 18.4556 8.13622 18.6663 8.66666 18.6663H15.3333C15.8638 18.6663 16.3725 18.4556 16.7475 18.0806C17.1226 17.7055 17.3333 17.1968 17.3333 16.6663V11.9997C17.3333 11.4692 17.1226 10.9605 16.7475 10.5855C16.3725 10.2104 15.8638 9.99967 15.3333 9.99967ZM9.99999 8.66634C9.99999 8.13591 10.2107 7.6272 10.5858 7.25213C10.9608 6.87705 11.4696 6.66634 12 6.66634C12.5304 6.66634 13.0391 6.87705 13.4142 7.25213C13.7893 7.6272 14 8.13591 14 8.66634V9.99967H9.99999V8.66634ZM16 16.6663C16 16.8432 15.9298 17.0127 15.8047 17.1377C15.6797 17.2628 15.5101 17.333 15.3333 17.333H8.66666C8.48985 17.333 8.32028 17.2628 8.19525 17.1377C8.07023 17.0127 7.99999 16.8432 7.99999 16.6663V11.9997C7.99999 11.8229 8.07023 11.6533 8.19525 11.5283C8.32028 11.4032 8.48985 11.333 8.66666 11.333H15.3333C15.5101 11.333 15.6797 11.4032 15.8047 11.5283C15.9298 11.6533 16 11.8229 16 11.9997V16.6663Z"
                  fill="#D6444C"
                />
              </svg>
            </div>
          )}
          <input
            disabled={used || !contractIsActive}
            type="radio"
            style={{
              display: "none",
            }}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              if (typeof onChange === "function") {
                onChange(e);
              }
            }}
          />
          <MainText checked={field.checked}>{petName}</MainText>
          <Text>{children}</Text>
        </div>
      </Label>
      {used && (
        <Label alert>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34874 18.9425 4.80691 17.0678 2.93219C15.1931 1.05746 12.6513 0.00294858 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.1539 14.1544 15.6542 15.6542C14.1544 17.1539 12.121 17.9976 10 18ZM10 9.5C9.73479 9.5 9.48043 9.60536 9.2929 9.79289C9.10536 9.98043 9 10.2348 9 10.5V13.5C9 13.7652 9.10536 14.0196 9.2929 14.2071C9.48043 14.3946 9.73479 14.5 10 14.5C10.2652 14.5 10.5196 14.3946 10.7071 14.2071C10.8946 14.0196 11 13.7652 11 13.5V10.5C11 10.2348 10.8946 9.98043 10.7071 9.79289C10.5196 9.60536 10.2652 9.5 10 9.5ZM10 5.5C9.75278 5.5 9.5111 5.57331 9.30554 5.71066C9.09998 5.84801 8.93976 6.04324 8.84516 6.27165C8.75055 6.50005 8.72579 6.75139 8.77402 6.99386C8.82225 7.23634 8.94131 7.45907 9.11612 7.63388C9.29094 7.8087 9.51367 7.92775 9.75614 7.97598C9.99862 8.02421 10.25 7.99946 10.4784 7.90485C10.7068 7.81024 10.902 7.65002 11.0393 7.44446C11.1767 7.2389 11.25 6.99723 11.25 6.75C11.25 6.41848 11.1183 6.10054 10.8839 5.86612C10.6495 5.6317 10.3315 5.5 10 5.5Z"
                  fill="#D6444C"
                />
              </svg>
            </div>
            Vous avez déjà été remboursé(e) d'une urgence pour {petName}. Vous
            pourrez à nouveau faire une demande le{" "}
            {format(new Date(pet.contract.end_date), "dd MMMM yyyy", {
              locale: fr,
            })}
            .
          </div>
        </Label>
      )}
      {!used && !contractIsActive && (
        <Label alert>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34874 18.9425 4.80691 17.0678 2.93219C15.1931 1.05746 12.6513 0.00294858 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.1539 14.1544 15.6542 15.6542C14.1544 17.1539 12.121 17.9976 10 18ZM10 9.5C9.73479 9.5 9.48043 9.60536 9.2929 9.79289C9.10536 9.98043 9 10.2348 9 10.5V13.5C9 13.7652 9.10536 14.0196 9.2929 14.2071C9.48043 14.3946 9.73479 14.5 10 14.5C10.2652 14.5 10.5196 14.3946 10.7071 14.2071C10.8946 14.0196 11 13.7652 11 13.5V10.5C11 10.2348 10.8946 9.98043 10.7071 9.79289C10.5196 9.60536 10.2652 9.5 10 9.5ZM10 5.5C9.75278 5.5 9.5111 5.57331 9.30554 5.71066C9.09998 5.84801 8.93976 6.04324 8.84516 6.27165C8.75055 6.50005 8.72579 6.75139 8.77402 6.99386C8.82225 7.23634 8.94131 7.45907 9.11612 7.63388C9.29094 7.8087 9.51367 7.92775 9.75614 7.97598C9.99862 8.02421 10.25 7.99946 10.4784 7.90485C10.7068 7.81024 10.902 7.65002 11.0393 7.44446C11.1767 7.2389 11.25 6.99723 11.25 6.75C11.25 6.41848 11.1183 6.10054 10.8839 5.86612C10.6495 5.6317 10.3315 5.5 10 5.5Z"
                  fill="#D6444C"
                />
              </svg>
            </div>
            Vous avez souscrit pour {petName} le{" "}
            {format(new Date(pet.contract.start_date), "dd MMMM yyyy", {
              locale: fr,
            })}
            . Vous pourrez utiliser son fonds d'urgence à partir du{" "}
            {format(startDate, "dd MMMM yyyy", {
              locale: fr,
            })}
            .
          </div>
        </Label>
      )}
    </div>
  );
}
