import dogHead from "./dog_head.svg";
import catHead from "./cat_head.svg";
import { useEffect, useState } from "react";
import { legal } from "src/config";
import { css, styled, theme } from "src/style/theme";
import { Pet } from "src/types";
import { fetchContracts } from "src/utils/backend";
import { button, ButtonIcon } from "./button";
import { DownloadFileIcon, DownloadIcon } from "./icons";
import { H3, Modal, ModalSeparator } from "./modal";
import { Stack } from "./stack";
import { differenceInCalendarMonths } from "date-fns";
import { formatDate } from "src/utils/date";

export function ContractsModal({ onDismiss }: { onDismiss: () => void }) {
  const [pets, setPets] = useState<Pet[] | null>(null);

  useEffect(() => {
    fetchContracts().then(setPets);
  }, []);
  return (
    <Modal title="Mes contrats" onDismiss={onDismiss}>
      <>
        {pets
          ? pets.map((pet) => {
              return <Contract key={pet.pet.pet_idx} pet={pet} />;
            })
          : null}
        <ModalSeparator />
        <H3 size="h5">Résiliation</H3>
        <p>
          Afin de résilier un ou plusieurs contrats, merci d'envoyer un email à{" "}
          <a
            href="mailto:resiliation@dalma.co"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: theme.colors.electric as any as string,
              textDecoration: "none",
            }}
          >
            <em
              style={{
                fontStyle: "normal",
                color: theme.colors.electric as any as string,
              }}
            >
              resiliation@dalma.co
            </em>
          </a>
          . Nous vous répondrons au plus vite.
        </p>
        <ModalSeparator />
        <Stack>
          <H3 size="h5">Autres documents</H3>
          <Stack css={{ $$margin: "12px" }}>
            <Document title="Fiche IPID" url={legal.v1.ipid} />
            <Document title="Conditions Générales" url={legal.v1.cgu} />
            <Document title="Mentions légales" url={legal.v1.legal_notice} />
            <Document
              title="Politique de confidentialité"
              url={legal.v1.privacy_policy}
            />
          </Stack>
        </Stack>
      </>
    </Modal>
  );
}

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",

  gap: 10,
});

const document = css({
  background: theme.colors.white,
  border: `1px solid ${theme.colors.gray300}`,
  borderRadius: 12,
  padding: "12px 16px",

  fontSize: 14,
  lineHeight: "16px",
  color: theme.colors.gray800,
});

function Document({ title, url }: { title: string; url: string }) {
  return (
    <div className={document()}>
      <Row>
        <div>{title}</div>
        <a href={url} download target="_blank" rel="noreferrer">
          <DownloadFileIcon color="#4E68F6" />
        </a>
      </Row>
    </div>
  );
}

const petClass = css({
  flex: "none",
  width: 314,
  maxWidth: "100%",
  margin: "auto",
  border: "1px solid #C3D7F0",
  borderRadius: 12,
  position: "relative",
  zIndex: 0,
  marginTop: 50,
  // Hack shrink space between pet cards
  marginBottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

function Contract({ pet }: { pet: Pet }) {
  return (
    <Stack>
      <PetCard pet={pet} />
      <Row>
        <div>Date de souscription</div>
        <div>
          {formatDate(new Date(pet.contract.start_date), "dd MMM yyyy")}
        </div>
      </Row>
      <Row>
        <div>N° de contrat</div>
        <div>{pet.contract.contract_uuid}</div>
      </Row>
      <a
        className={button()}
        href={pet.contract.adhesion_contract}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonIcon icon={<DownloadIcon />}>Télécharger le contrat</ButtonIcon>
      </a>
    </Stack>
  );
}

function PetCard({ pet }: { pet: Pet }) {
  return (
    <div className={petClass()}>
      <div style={{ padding: "16px", paddingBottom: "0px" }}>
        <div
          style={{
            position: "absolute",
            top: -40,
            left: "50%",
            transform: "translateX(-50%)",
            width: 80,
            zIndex: -1,
          }}
        >
          <span
            style={{
              display: "inline-block",
              backgroundColor: "white",
              borderRadius: "50%",
              width: "100%",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={pet.pet.pet_type === "dog" ? dogHead : catHead}
              alt=""
            />
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontFamily: "Roslindale",
                textAlign: "center",
                fontSize: 18,
                margin: "10px 0px 0px 0px",
                lineHeight: "28px",
              }}
            >
              {pet.pet.pet_name}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                color: "#757575",
                lineHeight: "22px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                  textAlign: "center",
                  fontSize: 14,
                }}
              >
                {pet.pet.pet_type === "dog" ? "Chien " : "Chat "}
                {pet.pet.pet_sexe === "MALE" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="#757575"
                  >
                    <path d="M16 2v2h3.586l-3.972 3.972c-1.54-1.231-3.489-1.972-5.614-1.972-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-2.125-.741-4.074-1.972-5.614l3.972-3.972v3.586h2v-7h-7zm-6 20c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="#757575"
                  >
                    <path d="M21 9c0-4.97-4.03-9-9-9s-9 4.03-9 9c0 4.632 3.501 8.443 8 8.941v2.059h-3v2h3v2h2v-2h3v-2h-3v-2.059c4.499-.498 8-4.309 8-8.941zm-16 0c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                  </svg>
                )}{" "}
                • {formatAge(pet.pet.pet_birthday)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Price
        price={pet.contract.after_discount}
        isDiscounted={
          pet.contract.after_discount < pet.contract.before_discount
        }
      />

      <div
        style={{
          textAlign: "center",
          fontSize: 12,
          lineHeight: "16px",

          /* Gris/600 */
          color: "#868896",
          marginBottom: 8,
        }}
      >
        Sans engagement
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "15px 8px",
          background: "rgba(197, 205, 252, 0.12)",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z"
              fill="#4E68F6"
            />
          </svg>
          <span>Fonds d'urgence de 2000€/an</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822V6.00822Z"
              fill="#4E68F6"
            />
          </svg>
          <span>Télérégulation illimitée</span>
        </div>
      </div>
    </div>
  );
}

const priceClass = css({
  color: theme.colors.electric,
  fontFamily: "Roslindale",
});

const month = css({
  marginLeft: 5,
  fontWeight: "bold",
  fontSize: 14,
  lineHeight: "16px",

  color: theme.colors.gray700,
});

function Price({
  price,
  isDiscounted,
}: {
  price: string;
  isDiscounted?: boolean;
}) {
  const [eur, cents] = price.split(".");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
      }}
    >
      <div className={priceClass()}>
        <span style={{ fontSize: 48, lineHeight: "64px" }}>{eur}</span>
        <span style={{ fontSize: 20, lineHeight: "24px" }}>,{cents}</span>
      </div>
      <span className={month()}>/mois</span>
      {isDiscounted ? (
        <span
          style={{
            display: "inline-block",
            marginLeft: 13,
            padding: 4,
            background: "#DDF0EA",
            fontWeight: 600,
            fontSize: 12,
            lineHeight: "12px",

            color: "#00704F",
          }}
        >
          -10%
        </span>
      ) : null}
    </div>
  );
}

function getMonthsSince(birthdate: string) {
  return differenceInCalendarMonths(new Date(), new Date(birthdate));
}

function formatAge(birthdate: string) {
  if (!birthdate) {
    return null;
  }
  const age = getMonthsSince(birthdate);
  const year = Math.floor(age / 12);
  const month = age % 12;
  const output = [];
  if (year > 0) {
    output.push(`${year} an${year === 1 ? "" : "s"}`);
  }
  if (month > 0) {
    output.push(`${month} mois`);
  }
  return output.join(" et ");
}
