import { useEffect, useState } from "react";
import { Pet, Profile } from "src/types";
import { fetchContracts, requestTelevet } from "src/utils/backend";
import { DalmaModal, H3 } from "src/components/modal";
import { DalmaText } from "src/components/dalma-text";
import { Input } from "src/components/input";
import { Form, Formik } from "formik";
import { SubmitButton } from "src/components/modal-submit-button";
import { Stack } from "src/components/stack";
import { CallIcon, ChatIcon, VisioIcon } from "src/components/icons";
import { ChatTypeCard } from "src/components/chat-type-card";
import { styled } from "src/style/theme";
import { Spinner } from "src/components/Spinner";
import { DalmaInfo } from "src/components/dalma-info";
import { useIntercom } from "src/use-intercom";

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",

  variants: {
    selectChat: {
      true: {
        flexDirection: "column",
        "@bp1": {
          flexDirection: "row",
        },
      },
    },
  },
});

function isValidPhone(phoneNumber: string) {
  const numCheck =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return numCheck.test(String(phoneNumber).toLowerCase());
}

function validate(
  profile: Pick<Profile, "firstname" | "lastname" | "email" | "phone"> & {
    type: string;
  }
) {
  const errors: any = {};
  if (!isValidPhone(profile.phone)) {
    errors.phone = "Veillez renseigner un numéro de téléphone valide";
  }
  return errors;
}

export function Vet({ profile, onClose }: { profile: Profile } & any) {
  const [pets, setPets] = useState<Pet[] | null>(null);
  const { show } = useIntercom();

  useEffect(() => {
    fetchContracts().then(setPets);
  }, []);

  const [state, setState] = useState(1);

  if (pets === null) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner />
      </div>
    );
  }
  if (state === 1) {
    return (
      <Formik
        initialValues={{
          firstname: profile.firstname,
          lastname: profile.lastname,
          email: profile.email,
          phone: profile.phone,
          type: "",
        }}
        validate={validate}
        validateOnMount
        onSubmit={async (values) => {
          const type = values.type;
          if (["call", "visio"].includes(type)) {
            await requestTelevet(type, values.phone);
          }
          if (type === "call") {
            setState(2.1);
          } else if (type === "visio") {
            setState(2.2);
          } else {
            setState(2.3);
          }
        }}
      >
        {({ isValid, isSubmitting, setFieldValue, values }) => (
          <Form style={{ marginBottom: 86 }}>
            <Stack direction="column">
              <Row selectChat>
                <ChatTypeCard
                  selected={values.type === "chat"}
                  onClick={() => {
                    setFieldValue("type", "chat");
                    show();
                  }}
                  icon={<ChatIcon color="#4E68F6" size={20} />}
                >
                  Par message
                </ChatTypeCard>
                <ChatTypeCard
                  selected={values.type === "call"}
                  onClick={() => setFieldValue("type", "call")}
                  icon={<CallIcon color="#4E68F6" size={20} />}
                >
                  Par téléphone
                </ChatTypeCard>
                <ChatTypeCard
                  selected={values.type === "visio"}
                  onClick={() => setFieldValue("type", "visio")}
                  icon={<VisioIcon color="#4E68F6" size={20} />}
                >
                  En vidéo
                </ChatTypeCard>
              </Row>
              {["", "chat"].includes(values.type) ? null : (
                <>
                  <div />
                  <H3>Vos infos</H3>
                  <DalmaText>
                    Confirmez le numéro de téléphone auquel notre vétérinaire
                    partenaire vous rappelera.
                  </DalmaText>
                  <Row>
                    <Input disabled name="firstname" label="Votre prénom" />
                    <div style={{ width: "16px" }} />
                    <Input disabled name="lastname" label="Votre nom" />
                  </Row>
                  <Input disabled name="email" label="Votre adresse email" />
                  <Input name="phone" label="Votre numéro de téléphone" />
                  <SubmitButton isLoading={isSubmitting} isValid={isValid}>
                    Continuer
                  </SubmitButton>
                </>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  } else if (state === 2.1) {
    return (
      <DalmaModal
        title="Gardez votre téléphone sur vous"
        onDismiss={onClose}
        transparentOverlay
      >
        Notre vétérinaire partenaire va vous appeler d’un moment à un autre 📞
        <div style={{ marginTop: 37 }}>
          <DalmaInfo>
            Il ou elle fera au maximum 3 tentatives d’appel. Au-delà, vous
            devrez refaire une demande d’appel.
          </DalmaInfo>
        </div>
      </DalmaModal>
    );
  } else if (state === 2.2) {
    return (
      <DalmaModal
        title="Surveillez vos SMS ou vos mails  👀"
        onDismiss={onClose}
        transparentOverlay
      >
        Nous vous enverrons un lien de connexion dès que notre vétérinaire
        partenaire sera connecté. ✉️
        <div style={{ marginTop: 37 }}>
          <DalmaInfo>
            Il ou elle attendra 3 min au maximum. Au-delà, vous devrez refaire
            une demande.
          </DalmaInfo>
        </div>
      </DalmaModal>
    );
  } else {
    return (
      <DalmaModal
        title="Gardez votre téléphone sur vous"
        onDismiss={onClose}
        transparentOverlay
      >
        Notre vétérinaire partenaire va vous appeler d’un moment à un autre 📞
      </DalmaModal>
    );
  }
}
